import React, { useEffect } from 'react'
import translate from '../../i18n/translate'
import './LoadingPage.scss'
import { Analysis } from '../../services/analysisService'
import logo from './../../assets/img/logo.svg'
import blueAvatar from './assets/avatars/BlueAvatarWithBubble.svg'
import purpleAvatar from './assets/avatars/PurpleAvatarWithBubble.svg'
import moon from './assets/moon.svg'
import redAvatar from './assets/avatars/RedAvatarWithBubble.svg'
import planet from './assets/planet.svg'
import greenAvatar from './assets/avatars/GreenAvatarWithBubble.svg'
import scissorsAvatar from './assets/avatars/ScissorsAvatarWithBubble.svg'
import rocket from './assets/rocket.svg'
import blueberro from './assets/avatars/BlueberroAvatarWithBubble.svg'
import orangeAvatar from './assets/avatars/OrangeAvatarWithBubble.svg'
import otherPlanetFullScreen from './assets/otherPlanet.svg'
import otherPlanetTablet from './assets/otherPlanet2.svg'
import planetVerticalTablet from './assets/planetVerticalTablet.svg'
import planetTabletVerticalSmall from './assets/planetTabletVerticalSmall.svg'
import planetTabletHorizontalSmall from './assets/planetTabletHorizontalSmall.svg'

const LoadingPage = ({ count }) => {
  const LOADING_BAR_MAX_SIZE = 343
  const LOADING_STEPS = 26
  useEffect(() => {
    // Temporal - para el cambio de urls de tener # a no tener y ser llamadas desde APPs
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ function: 'isNotPractice' })
      )
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Map Page']
    )
  }, [])

  function normalizeToRange(value) {
    const normalizedValue = (value / LOADING_STEPS) * LOADING_BAR_MAX_SIZE

    return normalizedValue
  }

  return (
    <div className="map-loading_page">
      <div className="map-loading_background">
        <div className="map-loading_avatars">
          <div className="map-loading_container">
            <div className="map-loading_header"></div>
            <div className="map-loading_logo">
              <img src={logo} alt="Loading..." />
            </div>
            <div className="map-loading_footer">
              <div className="map-loading_footer-progress-bar_background">
                <div
                  className="map-loading_footer-fill-progress-bar"
                  style={{ width: normalizeToRange(count) }}
                ></div>
              </div>
              <div className="map-loading_footer-text">
                {translate('map_loading_text')}
              </div>
            </div>
          </div>
          <div className="map-loading_avatars-top-left">
            <img
              className="map-loading_avatars-top-left-blue"
              src={blueAvatar}
            ></img>
            <img
              className="map-loading_avatars-top-left-purple"
              src={purpleAvatar}
            ></img>
            <img className="map-loading_avatars-top-left-moon" src={moon}></img>
          </div>
          <div className="map-loading_avatars-top-right">
            <img
              className="map-loading_avatars-top-right_red"
              src={redAvatar}
            ></img>
            <img
              className="map-loading_avatars-top-right_planet"
              src={planet}
            ></img>
          </div>
          <div className="map-loading_avatars-bottom-left">
            <img
              className="map-loading_avatars-bottom-left-green"
              src={greenAvatar}
            ></img>
            <img
              className="map-loading_avatars-bottom-left-scissors"
              src={scissorsAvatar}
            ></img>
            <img
              className="map-loading_avatars-bottom-left-rocket"
              src={rocket}
            ></img>
          </div>
          <div className="map-loading_avatars-bottom-right">
            <img
              className="map-loading_avatars-bottom-right-blueberro"
              src={blueberro}
            ></img>
            <img
              className="map-loading_avatars-bottom-right-orange"
              src={orangeAvatar}
            ></img>
            <img
              className="map-loading_avatars-bottom-right-other-planet-full-screen"
              src={otherPlanetFullScreen}
            ></img>
            <img
              className="map-loading_avatars-bottom-right-other-planet-horizontal-tablet"
              src={otherPlanetTablet}
            ></img>
            <img
              className="map-loading_avatars-bottom-right-other-planet-vertical-tablet"
              src={planetVerticalTablet}
            ></img>
            <img
              className="map-loading_avatars-bottom-right-other-planet-vertical-tablet-small"
              src={planetTabletVerticalSmall}
            ></img>
            <img
              className="map-loading_avatars-bottom-right-other-planet-horizontal-tablet-small"
              src={planetTabletHorizontalSmall}
            ></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage
