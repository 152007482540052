import React, { useRef, useState, useEffect } from 'react'
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/swiper-bundle.css'
import './AvatarSlider.scss'
import AvatarCardSlider from '../AvatarCardSlider/AvatarCardSlider'
import ConfirmPopupOptions from '../../../ConfirmPopupOptions/ConfirmPopupOptions'
import CardDetails from '../../../MetaTabCommons/CardDetails/CardDetails'
import translate from '../../../../../i18n/translate'

const AvatarSlider = ({
  avatars,
  avatarSelected,
  playSoundEffect,
  handleSelectAvatar,
  setDataAvatarPending,
  startSlide,
  isIntro,
  cardButtonText,
  isSelected,
  handleMainButton
}) => {
  // store controlled swiper instance
  const swiperRef = useRef(null)

  console.log('startSlide - ', startSlide)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [avatarSwiperSelected, setAvatarSwiperSelected] = useState(null)
  const [popupButtonText, setPopupButtonText] = useState('')
  const [isPopupButtonDisabled, setIsPopupButtonDisabled] = useState(false)

  const handleOpenPopup = () => {
    setIsPopupOpen(true)
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  const showCardDetail = (isSelected, avatar) => {
    if (isSelected) {
      setPopupButtonText(translate('avatar_selected'))
      setIsPopupButtonDisabled(true)
    } else {
      setPopupButtonText(translate('avatar_confirm'))
      setIsPopupButtonDisabled(false)
    }
    handleOpenPopup()
  }

  const onAcceptButtonClick = () => {
    playSoundEffect('avatarSelected')
    handleSelectAvatar(avatarSwiperSelected)
    handleClosePopup()
  }

  /* const [startSlideState, setStartSlideState] = useState()
  const [slides, setSlides] = useState([])
  const [clonesCountPerSide, setClonesCountPerSide] = useState(
    Math.floor(avatars.length / 2)
  )

  // Calcula startSlide y slides una vez, cuando avatars or selectedAvatar cambian
  // Esto se hace para que cuando hay un avatar seleccionado el slider se posicione en él
  // y no deje espacios en blanco al mostrar 5 elementos
  useEffect(() => {
    refreshSliders()
  }, [avatars, avatarSelected, clonesCountPerSide])
  

  const refreshSliders = () => {
    // Calcular el número de slides clonados necesarios en cada extremo
    setClonesCountPerSide(Math.floor(avatars.length / 2))
    // Crear los clones
    const firstClones = avatars.slice(0, clonesCountPerSide)
    const lastClones = avatars.slice(-clonesCountPerSide)
    // Combinar los clones con los slides originales
    setSlides([...lastClones, ...avatars, ...firstClones])
    // Encontrar el índice del slide inicial teniendo en cuenta los clones
    setStartSlideState(startSlide + clonesCountPerSide)
  } */

  const moveToIndex = (index) => {
    if (swiperRef.current) {
      /* setTimeout(() => {
        swiperRef.current.slideToLoop(index, 500, true)
        // swiperRef.current.update()
        // refreshSliders()
      }, 50) */
    }
  }

  useEffect(() => {
    if (swiperRef.current) {
      if (startSlide >= 0 && startSlide <= 5) {
        swiperRef.current.slideToLoop(startSlide)
      }
    }
  }, [startSlide])

  return avatars && avatars.length ? (
    <>
      <div className="avatar_slider__container">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
          spaceBetween={-30}
          slidesPerView={5}
          // loopAdditionalSlides={avatars.length}
          centeredSlides={true}
          centeredSlidesBounds={true}
          observer={true}
          observeParents={true}
          navigation
          onSwiper={(swiper) => {
            swiperRef.current = swiper
          }}
          initialSlide={startSlide}
          onSlideChange={() => {
            if (swiperRef.current) {
              /* const currentIndex =
                  (swiperRef.current.realIndex -
                    clonesCountPerSide +
                    avatars.length) %
                  avatars.length */
              const currentIndex = swiperRef.current.realIndex
              if (avatars && avatars.length > 0 && avatars[currentIndex]) {
                setDataAvatarPending(avatars[currentIndex] || [])
                setAvatarSwiperSelected(avatars[currentIndex] || [])
              }
            }
          }}
          loop={true}
          className={isIntro ? 'avatar-swiper-intro' : 'avatar-swiper'}
        >
          {avatars?.map((avatar, index) => (
            <SwiperSlide key={index}>
              <div
                className={`meta-popup-selector__grid-item ${
                  avatarSelected.single_image === avatar.single_image
                    ? 'selected-slider'
                    : 'no-selected-slider'
                }`}
                key={avatar.guid}
              >
                <AvatarCardSlider
                  isSelected={
                    avatarSelected.single_image === avatar.single_image
                  }
                  avatarImage={avatar.single_image}
                  avatarName={avatar.name}
                  avatarDescription={avatar.description}
                  onClickSelect={() => {
                    playSoundEffect('slider')
                    handleSelectAvatar(avatar)
                  }}
                  isIntro={isIntro}
                  index={index}
                  moveToIndex={moveToIndex}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div>
        {!isIntro && cardButtonText && (
          <div className="avatar_slider__button-container">
            <div
              className={`avatar_slider__button--${
                isSelected ? 'default' : 'available'
              }`}
              onClick={() => {
                showCardDetail(
                  avatarSelected.single_image ===
                    avatars[swiperRef.current?.realIndex]?.single_image,
                  avatars[swiperRef.current?.realIndex]
                )
              }}
            >
              {cardButtonText}
            </div>
          </div>
        )}
      </div>
      {isPopupOpen && (
        <ConfirmPopupOptions
          aditionalClass="card-preview"
          size="xs"
          acceptButtonText={popupButtonText}
          onAcceptButtonClick={onAcceptButtonClick}
          isAcceptButtonDisabled={isPopupButtonDisabled}
          onClose={handleClosePopup}
          showBtnCancel={false}
        >
          <CardDetails
            name={avatars[swiperRef.current?.realIndex]?.name}
            description={avatars[swiperRef.current?.realIndex]?.description}
            image={avatars[swiperRef.current?.realIndex]?.card_image}
            isSelected={
              avatarSelected.single_image ===
              avatars[swiperRef.current?.realIndex]?.single_image
            }
          />
        </ConfirmPopupOptions>
      )}
    </>
  ) : null
}

export default AvatarSlider
