import React from 'react'
import translate from '../../../../i18n/translate'
import FormButton from '../../../BlueberryFamilyPages/components/FormButton/FormButton'
import { ReactComponent as BackIcon } from '../../../../assets/icons/arrow-circle-left.svg'
import { ReactComponent as Check } from '../../../../assets/img/check-circle.svg'

function ChangeSuccess({ onButtonClick }) {
  return (
    <div className={'password-form'}>
      <div className="form-image">
        <Check />
      </div>
      <h2 className="form-title">{translate('new_password_success_title')}</h2>
      <div className="form-text">{translate('new_password_success_text')}</div>
      <div className="submit-control">
        <FormButton
          icon={<BackIcon />}
          text={translate('recover_password_back')}
          textAlign="left"
          onClick={onButtonClick}
        />
      </div>
    </div>
  )
}

export default ChangeSuccess
