import React, { useEffect } from 'react'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import { ReactComponent as BatteryIcon } from '../../../../../assets/img/metaberry_ui/small_battery.svg'
import BlueberroImage from '../../assets/bb_idle.png'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'

const OnboardingBrokenBattery = ({ handleCheck }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'onboardingBrokenBattery_' + langCode

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_broken_battery',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <>
      <div className="onboarding-panel__backdrop" onClick={handleCheck}></div>
      <div className="onboarding-panel">
        {renderSoundFXController()}
        <div className="onboarding-panel__top"></div>
        <AudioBtn
          className="onboarding-panel__audio left"
          onClick={handleAudio}
        />
        <div className="onboarding-panel__close-icon" onClick={handleCheck}>
          <CloseIcon />
        </div>
        <div className="onboarding-panel__title">
          {translate('onboarding_broken_battery_title')}
        </div>
        <div className="onboarding-panel__text">
          <p>{translate('onboarding_broken_battery_text_1')}</p>
          <p className="onboarding-panel__text-with-icon">
            {translate('onboarding_broken_battery_text_2')}
            <span>
              <BatteryIcon />
            </span>
            {translate('onboarding_broken_battery_text_3')}
          </p>
        </div>
        <div className="onboarding-panel__bb-image">
          <img src={BlueberroImage} alt="" />
        </div>
        <div className="onboarding-panel__bottom"></div>
      </div>
    </>
  )
}
export default OnboardingBrokenBattery
