import React, { useState } from 'react'
import './mapTabsTooltip.scss'

const MapTabsTooltip = ({
  width = '160px',
  arrowPosition = 'center',
  children,
  message,
  contentComponent,
  isDisabled
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = () => {
    setIsVisible(!isVisible)
  }

  return isDisabled ? (
    <>
      {isVisible && (
        <div className="map-tabs-tooltip__backdrop" onClick={handleClick}></div>
      )}
      <div className="map-tabs-tooltip">
        <div className="map-tabs-tooltip__element" onClick={handleClick}>
          {children}
        </div>

        <div
          style={{ width: width }}
          className={`map-tabs-tooltip__tooltip map-tabs-tooltip__tooltip--${arrowPosition} ${
            isVisible ? 'map-tabs-tooltip__tooltip--visible' : ''
          }`}
        >
          {message}
          {contentComponent && (
            <div className="map-tabs-tooltip__content">{contentComponent}</div>
          )}
        </div>
      </div>
    </>
  ) : (
    <>{children}</>
  )
}

export default MapTabsTooltip
