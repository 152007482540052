import { getConfig } from 'react-lemonade-editor'
// import { getCurrentLangString } from '../i18n/initI18n'

export function setLemonadeDefaultConfig(mathRenderEngine) {
  window.lemoConfig = getConfig()

  getConfig().mathRenderEngine = mathRenderEngine
  // getConfig().setLang(getCurrentLangString())
  getConfig().setLang('es')
  getConfig().setIndividualFeedbackTooltip(false)
  getConfig().setShowFeedback(true)
  getConfig().setChartsEnable(true)
  getConfig().setGraphsHelpMarks(false)
  getConfig().autoFocus = 'enabled'
  getConfig().showAutoFeedback = false // Intercambio automático de respuesta/solución
  getConfig().useOrderListInMobileMode = true
  // getConfig().setMathFieldIcon(false)

  getConfig().setDecimalSeparator('POINT')
  getConfig().setThousandsSeparator('SPACE')
}

export function setLemonadeCustomConfig(courseLemonadeSettings) {
  getConfig().setDecimalSeparator(courseLemonadeSettings.decimal_separator)
  getConfig().setThousandsSeparator(courseLemonadeSettings.thousands_separator)
}

// export function setLemonadeCustomLanguage() {
//   getConfig().setLang(getCurrentLangString())
// }
