import React, { useEffect, useState } from 'react'
// import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
// import translate from '../../../../../i18n/translate'

import installImg from '../../assets/status_install.png'
import initImg from '../../assets/status_init.png'
import chargingImg from '../../assets/status_charging.png'
import emptyImg from '../../assets/status_empty.png'
import completeImg from '../../assets/status_complete.png'
import brokedtImg from '../../assets/status_broked_start.png'
import startImg from '../../assets/status_start.png' // Se usa la imagen de complete para no confundir a los estudiantes
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'

import { getBatteryChange } from '../../../../../services/popupManagerService'

const RewardBatteryStatus = ({ handleCheck }) => {
  // Battery status animation classes states
  const [containerVibration, setContainerVibration] = useState(true)
  const [newBatteryPulsate, setNewBatteryPulsate] = useState(false)

  useEffect(() => {
    setContainerVibration(true)

    const animateBatteryTimer = setTimeout(() => {
      setContainerVibration(false)
      setNewBatteryPulsate(true)
    }, 2000)

    return () => {
      clearTimeout(animateBatteryTimer)
    }
  }, [])

  const statusImages = {
    install: installImg,
    init: initImg,
    charging: chargingImg,
    start: startImg,
    complete: completeImg,
    broked_start: brokedtImg,
    empty: emptyImg
  }

  return (
    <>
      <div className="reward-popup__backdrop" onClick={handleCheck}></div>
      <div className="reward-popup">
        <div className="reward-popup__top"></div>
        <div className="reward-popup__close-icon" onClick={handleCheck}>
          <CloseIcon />
        </div>
        <div
          className={`battery-status-change__container ${
            containerVibration ? 'vibrate' : ''
          }`}
        >
          <div
            className={`battery-status-change__image flicker-in ${
              newBatteryPulsate ? 'pulsate-fwd' : ''
            }`}
          >
            <img
              src={statusImages[getBatteryChange().newStatus]}
              alt=""
              draggable="false"
            />
          </div>
          <div className={`battery-status-change__image flicker-out`}>
            <img
              src={statusImages[getBatteryChange().oldStatus]}
              alt=""
              draggable="false"
            />
          </div>
        </div>
        <div className="reward-popup__bottom"></div>
      </div>
    </>
  )
}
export default RewardBatteryStatus
