import React, { useEffect } from 'react'

import useMetaTabAvatar from './useMetaTabAvatar'
import PopupSelector from '../PopupSelector/PopupSelector'
import AvatarSlider from './components/AvatarSlider/AvatarSlider'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../services/analysisService'
import AvatarData from './components/AvatarData/AvatarData'
import translate from '../../../i18n/translate'
import ItemDetail from '../MetaTabCommons/ItemDetail/ItemDetail'
import { setAvatarsNotification } from '../../../services/avatarService'

const MetaTabAvatar = ({ isIntro, handleIntroContinue, userExperience }) => {
  const {
    handleSelectAvatar,
    avatarsData,
    avatarSelected,
    isLoading,
    showToast,
    setDataAvatarPending,
    avatarPending,
    startSlide,
    avatarsTotal
  } = useMetaTabAvatar()

  const { renderSoundFXController, playSoundEffect } = useSound()

  const typePopupSelector = {
    AVATAR: 'avatar',
    PETS: 'pet'
  }

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Avatar Selector Page Viewed']
    )

    setAvatarsNotification(false)
  }, [])

  const contentAvatar = (
    <>
      <AvatarSlider
        avatars={avatarsData}
        avatarSelected={avatarSelected}
        playSoundEffect={playSoundEffect}
        handleSelectAvatar={handleSelectAvatar}
        setDataAvatarPending={setDataAvatarPending}
        startSlide={startSlide}
        isIntro={isIntro}
        cardButtonText={
          avatarSelected.single_image === avatarPending.single_image
            ? translate('avatar_selected')
            : translate('avatar_select')
        }
        isSelected={avatarSelected.single_image === avatarPending.single_image}
        handleMainButton={() => {
          handleSelectAvatar(avatarPending)
        }}
      />
      {isIntro && (
        <AvatarData
          title={avatarPending.name}
          description={avatarPending.description}
        />
      )}
      {!isIntro && avatarsTotal && (
        <div className="metatabavatar-container-total">
          {avatarsTotal?.map((avatarTotal, index) => (
            <div
              className="metatabavatar-container-total__item"
              key={avatarTotal.guid}
            >
              <ItemDetail
                isSelected={
                  avatarSelected.single_image === avatarTotal.single_image
                }
                avatar={avatarTotal}
                onClickSelect={() => {
                  playSoundEffect('avatarSelected')
                  handleSelectAvatar(avatarTotal)
                }}
                isIntro={false}
                isDisabled={avatarTotal.level > userExperience?.level}
                level={avatarTotal.level}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )

  return (
    <>
      {renderSoundFXController()}
      <PopupSelector
        isIntro={isIntro}
        handleMainButton={() => {
          handleSelectAvatar(avatarPending)
          handleIntroContinue()
        }}
        isLoading={isLoading}
        showToast={showToast}
        content={contentAvatar}
        type={typePopupSelector.AVATAR}
        typePopupSelector={typePopupSelector}
        showMainButton={isIntro}
        textMainButton={translate('avatar_select')}
        level={userExperience?.level}
      />
    </>
  )
}

export default MetaTabAvatar
