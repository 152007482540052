import { useState, useEffect, useRef } from 'react'
import {
  getFirstExercise,
  sendUserAnswer,
  sendIssuesUserReport,
  notifyQuestionViewedByUser
} from '../../../services/practiceServicesReactNative'
import useLemonadeOnboarding from '../../../../../hooks/useLemonadeOnboarding'
import { APP_ANALYSIS } from '../../../services/analysisServiceReactNative'
import { Analysis } from '../../../../../services/analysisService'
import { getWhoAmI } from '../../../../../services/courseInfoService'
import { getAudio } from '../../../../../services/audioService'
import { BREAKPOINTS } from '../../../../../_practice-components/constants/uiConstants'

export function usePracticeView({
  practiceBaseData,
  setPracticeBaseData,
  practiceProgressData,
  setPracticeProgressData,
  setPracticeState,
  messageToReactNative,
  playSoundEffect,
  practiceViewRef
}) {
  const [activityShowed, setActivityShowed] = useState(null)

  const [isBeforeFirstExercise, setIsBeforeFirstExercise] = useState(true)
  const [practiceStartedDate, setPracticeStartedDate] = useState(null)
  const [numberExercisesCorrect, setNumberExercisesCorrect] = useState(0)
  const [numberExercisesIncorrect, setNumberExercisesIncorrect] = useState(0)
  const [currentExercise, setCurrentExercise] = useState(null)
  const [questionData, setQuestionData] = useState(null)
  const [previousSeedId, setPreviousSeedId] = useState(undefined)
  const [nextExercise, setNextExercise] = useState(null)
  const [experienceTotal, setExperienceTotal] = useState(0)
  const [experienceNormal, setExperienceNormal] = useState(0)
  const [experienceExtra, setExperienceExtra] = useState(0)
  const [hasMoreExercises, setHasMoreExercises] = useState(false)
  const [showAbortPracticePopup, setShowAbortPracticePopup] = useState(false)
  const [showIssueReportPopup, setShowIssueReportPopup] = useState(false)
  const [showReportSentToast, setShowReportSentToast] = useState(-1)
  const [showEarnedPiecePopup, setShowEarnedPiecePopup] = useState(false)
  const [hasCheckExerciseAPiResponded, setHasCheckExerciseAPiResponded] =
    useState(false)
  // const [isPartialMatchFeedback, setIsPartialMatchFeedback] = useState(false)
  const [hasPreviowsPopups, setHasPreviowsPopups] = useState(true)

  const [showLemonadeOnboarding, setShowLemonadeOnboarding] = useState(false)
  const { checkLemonadeOnboarding, getTemplateKey } = useLemonadeOnboarding()

  const resizePracticeViewTimer = useRef(null)

  const [widthClass, setWidthClass] = useState('')

  useEffect(() => {
    window.addEventListener('resize', handlePracticeViewResize)

    return () => {
      window.removeEventListener('resize', handlePracticeViewResize)
    }
  }, [])

  useEffect(() => {
    if (!isBeforeFirstExercise) {
      if (practiceViewRef?.current) {
        calculateWidthClass(practiceViewRef?.current?.offsetWidth)
      }
    }
  }, [isBeforeFirstExercise])

  const handlePracticeViewResize = () => {
    if (resizePracticeViewTimer.current)
      clearTimeout(resizePracticeViewTimer.current)
    resizePracticeViewTimer.current = setTimeout(() => {
      const currentWidth = practiceViewRef?.current?.offsetWidth
      if (currentWidth) {
        calculateWidthClass(currentWidth)
      }
    }, 100)
  }

  const calculateWidthClass = (width) => {
    if (width) {
      let _widthClass = ' practice-view_desktop-size'

      if (width < BREAKPOINTS.desktop) {
        if (width < BREAKPOINTS.tablet) {
          _widthClass = ' practice-view_phone-size'
        } else {
          _widthClass = ' practice-view_tablet-size'
        }
      }

      setWidthClass(_widthClass)
    }
  }

  const resetActivityStates = () => {
    setHasMoreExercises(false)
    setShowLemonadeOnboarding(false)
    setHasCheckExerciseAPiResponded(false)
    setHasPreviowsPopups(true)
  }

  const resetPracticeStates = () => {
    resetActivityStates()
    setCurrentExercise(null)
    setPracticeStartedDate(new Date())
    setNumberExercisesCorrect(0)
    setNumberExercisesIncorrect(0)
    setNextExercise(null)
    setExperienceTotal(0)
    setExperienceNormal(0)
    setExperienceExtra(0)
    setShowAbortPracticePopup(false)
    setShowEarnedPiecePopup(false)
  }

  const getPracticeStartExercise = async () => {
    const initialExercise = await getFirstExercise(
      practiceBaseData.courseGuid,
      practiceBaseData.lessonGuid,
      practiceBaseData.lessonChallenge,
      practiceBaseData.language,
      practiceBaseData.isFromApp
    ).catch((reason) => {
      console.error('Error at getPracticeStartExercise:', reason)
    })

    try {
      if (initialExercise) {
        // Obtener data de App o Web según corresponda
        const _questionData = getWebOrAppData(initialExercise)
        setQuestionData(_questionData)

        // Scaffolding
        const isScaffoldAux = _questionData.type === 'scaffold'

        // updateExercise({ data: !isScaffoldAux ? _questionData : _questionData.questions[0] })
        setActivityShowed(_questionData)

        // Se indica a API que se muestra actividad a estudiante
        try {
          const _nodeGuid = initialExercise.guid
          notifyQuestionViewedByUser(_nodeGuid, practiceBaseData.isFromApp)
        } catch (notifyError) {}

        setCurrentExercise(initialExercise)
        setIsBeforeFirstExercise(false)
        onNewActitivy(initialExercise, _questionData, isScaffoldAux)
        messageToReactNative({ function: 'onInitialized' })
      }
    } catch (reason) {
      console.error('Error at getPracticeStartExercise:', reason)
    }
  }

  const getStimulusAudio = async (signal) => {
    const audioLanguage = practiceBaseData.language
    const isFromApp = practiceBaseData.isFromApp

    return await getAudio(
      undefined,
      currentExercise?.question?.id,
      audioLanguage,
      signal,
      isFromApp
    )
  }

  const getTextAudio = async (text, signal) => {
    const audioLanguage = practiceBaseData.language
    const isFromApp = practiceBaseData.isFromApp

    return await getAudio(text, undefined, audioLanguage, signal, isFromApp)
  }

  const checkAnswerPractice = async (
    isUserCorrect,
    userResponse,
    isScaffold
  ) => {
    if (isUserCorrect) {
      setNumberExercisesCorrect(numberExercisesCorrect + 1)
    } else {
      setNumberExercisesIncorrect(numberExercisesIncorrect + 1)
    }

    sendUserAnswer({
      nodeGuid: currentExercise.guid,
      responseValue: isUserCorrect,
      userResponse,
      language: practiceBaseData.language,
      isScaffolding: isScaffold,
      isFromApp: practiceBaseData.isFromApp
    })
      .then((checkResponse) => {
        // Experiencia obtenida
        let earnedExperience = 0
        let earnedExperienceSurplus = 0
        if (isUserCorrect) {
          earnedExperience = parseInt(checkResponse.exp)
          setExperienceNormal(earnedExperience)

          if (checkResponse.surplus_exp) {
            earnedExperienceSurplus = parseInt(checkResponse.surplus_exp)
            setExperienceExtra(earnedExperienceSurplus)
          }
        }

        // Actualizar ejercicio almacenado (o null) ejercicio (se usa al dar a continuar)
        if (checkResponse.next_lo) {
          if (checkResponse.next_lo?.question?.data) {
            setHasMoreExercises(true)
          }

          setNextExercise(checkResponse.next_lo)
        }

        const responsePieces = checkResponse.pieces
        const earnedBronzePiece =
          responsePieces.have[0] - responsePieces.haved[0]
        const earnedSilverPiece =
          responsePieces.have[1] - responsePieces.haved[1]
        const earnedGoldPiece = responsePieces.have[2] - responsePieces.haved[2]
        setShowEarnedPiecePopup(
          earnedBronzePiece > 0 || earnedSilverPiece > 0 || earnedGoldPiece > 0
        )

        const piecesAux = practiceProgressData.earnedPieces

        setPracticeProgressData({
          sessionId: currentExercise?.session_id,
          earnedExperience: earnedExperience,
          earnedExtraExperience: earnedExperienceSurplus,
          earnedPieces: [
            piecesAux[0] + earnedBronzePiece,
            piecesAux[1] + earnedSilverPiece,
            piecesAux[2] + earnedGoldPiece
          ]
        })

        const usableChallenge =
          checkResponse.lesson_status === 'broked_start' &&
          checkResponse.challenges[1]
            ? checkResponse.challenges[1]
            : checkResponse.challenges[0]

        setPracticeBaseData({
          ...practiceBaseData,
          updatedLessonChallenge: usableChallenge.name,
          updatedLessonChallengeExp: usableChallenge.exp,
          updatedLessonStatus: checkResponse.lesson_status,
          userPieces: checkResponse.pieces.have
        })

        setHasCheckExerciseAPiResponded(true)
      })
      .catch((reason) => {
        console.error('Error at sendUserAnswer:', reason)
      })

    return isUserCorrect
  }

  const continuePractice = () => {
    if (hasMoreExercises) {
      resetActivityStates()

      const _questionData = getWebOrAppData(nextExercise)
      setQuestionData(_questionData)

      // Temporal para scaffolding
      const isScaffoldAux = _questionData.type === 'scaffold'

      // updateExercise({ data: !isScaffoldAux ? _questionData : _questionData.questions[0] })
      setActivityShowed(_questionData)

      // Se indica a API que se muestra actividad a estudiante
      try {
        const _nodeGuid = nextExercise.guid
        notifyQuestionViewedByUser(_nodeGuid, practiceBaseData.isFromApp)
      } catch (notifyError) {}

      setPreviousSeedId(currentExercise?.question?.seed_guid)
      setCurrentExercise(nextExercise)
      onNewActitivy(nextExercise, _questionData, isScaffoldAux)
      setNextExercise(null)
    } else {
      setPracticeState(2)
    }
  }

  const onNewActitivy = (_exercise, _questionData, _isScaffold) => {
    if (!_isScaffold) {
      activityLemonadeOnboarding(_questionData)
    } else {
      setHasPreviowsPopups(false)
    }

    APP_ANALYSIS.sendAnalysisEventToReactNative(
      practiceBaseData.isFromApp,
      APP_ANALYSIS.APP_ANALYSIS_EVENT['Activity Viewed'],
      {
        practice_session_id: _exercise?.session_id,
        seed_id: _exercise?.question?.seed_guid,
        activity_template: _questionData?.type,
        process: _exercise?.phase,
        activity_id: _exercise?.guid,
        lesson_id: _exercise?.lesson_guid,
        unit_id: _exercise?.unit_guid,
        node_department: _exercise?.node_department,
        // kc_status:
        lo_id: _exercise?.learning_objective_name,
        // lo_status:
        // first_time: _exercise?.amount_activities === 0,
        is_retest: _exercise?.is_relearning,
        node_name: _exercise?.node_name,
        node_id: _exercise?.node_guid,
        is_scaffolding: _isScaffold
      }
    )
  }

  const activityLemonadeOnboarding = (_questionData) => {
    // ONBOARDING ACTIVIDADES
    if (
      !practiceBaseData.isFromApp &&
      _questionData?.metadata &&
      _questionData?.metadata?.name
    ) {
      // Comprueba si se tiene que mostrar el onboarding de este template
      const templateKey = getTemplateKey(
        _questionData.metadata.name,
        _questionData.data
      )

      const hasToShowLemonadeOnboarding = checkLemonadeOnboarding(templateKey)
      setShowLemonadeOnboarding(hasToShowLemonadeOnboarding)

      if (!hasToShowLemonadeOnboarding) {
        setHasPreviowsPopups(false)
      }
    }
  }

  const getWebOrAppData = (_exercise) => {
    const hasMobileVersion =
      practiceBaseData.isFromApp &&
      _exercise?.question?.data_app &&
      Object.keys(_exercise.question.data_app).length > 0

    return hasMobileVersion
      ? _exercise.question.data_app
      : _exercise.question.data
  }

  const sendIssuesReport = async (reportData) => {
    APP_ANALYSIS.sendAnalysisEventToReactNative(
      practiceBaseData.isFromApp,
      Analysis.SEGMENT_EVENTS['Activity Issue Reported'],
      {
        seed_name: currentExercise?.question?.seed_guid,
        node_guid: currentExercise?.node_guid,
        node_name: currentExercise?.node_name,
        lesson_guid: practiceBaseData.lessonGuid
      }
    )

    const issueData = {
      userEmail: undefined,
      playerGuid: undefined,
      seedName: currentExercise?.question?.seed_guid,
      lessonGuid: practiceBaseData.lessonGuid,
      issues: reportData.issues,
      comment: reportData.userComment,
      isFromApp: practiceBaseData.isFromApp,
      userAgent: window.navigator.userAgent,
      screenshot: reportData.screenshot
    }

    try {
      if (!practiceBaseData.isFromApp) {
        const playerWhoAmI = await getWhoAmI()
        issueData.userEmail = playerWhoAmI.email
        issueData.playerGuid = playerWhoAmI.guid
        // Si es hijo y no tiene email, se pide al padre
        if (!playerWhoAmI.userEmail) {
          const mainWhoAmI = await getWhoAmI(true)
          issueData.userEmail = mainWhoAmI.email
        }
      } else {
        issueData.userEmail = practiceBaseData.userEmail
        issueData.playerGuid = practiceBaseData.userGuid
      }
    } catch (e) {}

    let sentOk = true
    const issuesUserReportResponse = await sendIssuesUserReport(
      issueData
    ).catch((reason) => {
      console.error('Error at sendIssuesUserReport:', reason)
      sentOk = false
    })

    if (sentOk && !issuesUserReportResponse.error) {
      setShowReportSentToast(0)
    } else {
      setShowReportSentToast(1)
    }
  }

  const onActivityEvent = (event) => {
    switch (event.name) {
      case 'Check Answer': {
        playSoundEffect(
          event.properties.isUserCorrect ? 'correct' : 'incorrect'
        )

        checkAnswerPractice(
          event.properties.isUserCorrect,
          event.properties.userResponse,
          event.properties.isScaffold
        )
        break
      }

      case 'Continue Clicked': {
        continuePractice()
        break
      }

      case 'TTS Clicked': {
        APP_ANALYSIS.sendAnalysisEventToReactNative(
          practiceBaseData.isFromApp,
          APP_ANALYSIS.APP_ANALYSIS_EVENT['TTS Clicked'],
          {
            object: event?.properties?.object,
            ui_element_id: event?.properties?.ui_element_id,
            seed_id: currentExercise?.question?.seed_guid
          }
        )

        break
      }

      case 'Hint Clicked': {
        APP_ANALYSIS.sendAnalysisEventToReactNative(
          practiceBaseData.isFromApp,
          APP_ANALYSIS.APP_ANALYSIS_EVENT['Hint Clicked'],
          {
            practice_session_id: currentExercise?.session_id,
            seed_id: currentExercise?.question?.seed_guid,
            learning_outcome_id: currentExercise?.learning_objective_name,
            process: currentExercise?.phase
          }
        )

        playSoundEffect('hint')

        break
      }

      case 'Show Solution Clicked': {
        const showSolutionClickedEventProperties = {
          practice_session_id: currentExercise?.session_id,
          seed_id: currentExercise?.question?.seed_guid,
          activity_template: questionData?.type,
          process: currentExercise?.phase,
          activity_id: currentExercise?.guid,
          lesson_id: currentExercise?.lesson_guid,
          unit_id: currentExercise?.unit_guid,
          learning_outcome_id: currentExercise?.learning_objective_name,
          result: event?.properties?.result,
          hint_used: event?.properties?.hint_used
        }

        APP_ANALYSIS.sendAnalysisEventToReactNative(
          practiceBaseData.isFromApp,
          APP_ANALYSIS.APP_ANALYSIS_EVENT['Show Solution Clicked'],
          showSolutionClickedEventProperties
        )
        break
      }

      case 'See My Answer Clicked': {
        APP_ANALYSIS.sendAnalysisEventToReactNative(
          practiceBaseData.isFromApp,
          APP_ANALYSIS.APP_ANALYSIS_EVENT['See My Answer Clicked'],
          {
            practice_session_id: currentExercise?.session_id,
            seed_id: currentExercise?.question?.seed_guid,
            activity_id: currentExercise?.guid
          }
        )

        break
      }

      case 'Activity Feedback Viewed': {
        APP_ANALYSIS.sendAnalysisEventToReactNative(
          practiceBaseData.isFromApp,
          APP_ANALYSIS.APP_ANALYSIS_EVENT['Activity Feedback Viewed'],
          {
            practice_session_id: currentExercise?.session_id,
            seed_id: currentExercise?.question?.seed_guid,
            activity_template: questionData?.type,
            process: currentExercise?.phase,
            activity_id: currentExercise?.guid,
            lesson_id: currentExercise?.lesson_guid,
            unit_id: currentExercise?.unit_guid,
            node_department: currentExercise?.node_department,
            // kc_status:
            lo_id: currentExercise?.learning_objective_name,
            // lo_status:
            // first_time: currentExercise?.amount_activities === 0,
            is_retest: currentExercise?.is_relearning,
            node_name: currentExercise?.node_name,
            node_id: currentExercise?.node_guid,
            is_scaffolding: event?.properties?.is_scaffolding
          }
        )

        break
      }
    }
  }

  return {
    isBeforeFirstExercise,
    experienceTotal,
    experienceNormal,
    experienceExtra,
    setShowAbortPracticePopup,
    currentExercise,
    previousSeedId,
    numberExercisesCorrect,
    numberExercisesIncorrect,
    practiceStartedDate,
    activityShowed,
    onActivityEvent,
    showLemonadeOnboarding,
    setShowLemonadeOnboarding,
    hasPreviowsPopups,
    setHasPreviowsPopups,
    questionData,
    getTemplateKey,
    showAbortPracticePopup,
    showEarnedPiecePopup,
    showIssueReportPopup,
    setShowIssueReportPopup,
    sendIssuesReport,
    showReportSentToast,
    setShowReportSentToast,
    resetPracticeStates,
    setExperienceTotal,
    getPracticeStartExercise,
    setShowEarnedPiecePopup,
    hasCheckExerciseAPiResponded,
    getStimulusAudio,
    getTextAudio,
    widthClass
  }
}
