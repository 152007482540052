import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import OnboardingIntroduction from './components/OnboardingIntroduction/OnboardingIntroduction'
import OnboardingFirstMoves from './components/OnboardingFirstMoves/OnboardingFirstMoves'
import OnboardingBatteryComplete from './components/OnboardingBatteryComplete/OnboardingBatteryComplete'
import OnboardingBatteryCharged from './components/OnboardingBatteryCharged/OnboardingBatteryCharged'
import OnboardingFirstPiece from './components/OnboardingFirstPiece/OnboardingFirstPiece'
import OnboardingUnlockedLesson from './components/OnboardingUnlockedLesson/OnboardingUnlockedLesson'
import OnboardingUnlockedUnit from './components/OnboardingUnlockedUnit/OnboardingUnlockedUnit'
import OnboardingBrokenBattery from './components/OnboardingBrokenBattery/OnboardingBrokenBattery'
import OnboardingFirstUserLevel from './components/OnboardingFirstUserLevel/OnboardingFirstUserLevel'
import RewardDailyGoal from './components/RewardDailyGoal/RewardDailyGoal'
import RewardBatteryStatus from './components/RewardBatteryStatus/RewardBatteryStatus'
import RewardBatteryUnlock from './components/RewardBatteryUnlock/RewardBatteryUnlock'

import usePopupManager from './usePopupManager'

const PopupManager = ({ handleFinishIntro, handleGoToPetsSelector }) => {
  const {
    popupStore,
    hasPopup,
    getFirstPopup,
    checkPopup,
    pushPopup,
    onboarding,
    resetOnboarding,
    hasOnboarding,
    handleCheckIntroduction,
    pushOnboardingPanel,
    handleCheckOnboardingPanel,
    showIntroduction
  } = usePopupManager()

  const isDevMode = useSelector((state) => state.configuration.isDevMode)
  // const [batteryChargedAllPieces, setBatteryChargedAllPieces] = useState(false)
  // const [batteryStatusChange, setBatteryStatusChange] = useState({})

  const popupComponent = {
    onboardingFirstMoves: hasOnboarding() ? (
      <OnboardingFirstMoves
        handleCheck={() => handleCheckOnboardingPanel('firstMoves')}
      />
    ) : null,

    onboardingFirstPiece: hasOnboarding() ? (
      <OnboardingFirstPiece
        handleCheck={() => handleCheckOnboardingPanel('firstPiece')}
      />
    ) : null,

    onboardingLessonUnlocked: hasOnboarding() ? (
      <OnboardingUnlockedLesson
        handleCheck={() => handleCheckOnboardingPanel('firstUnlockedLesson')}
      />
    ) : null,

    onboardingUnitUnlocked: hasOnboarding() ? (
      <OnboardingUnlockedUnit
        handleCheck={() => handleCheckOnboardingPanel('firstUnlockedUnit')}
      />
    ) : null,

    onboardingFirstUserLevel: hasOnboarding() ? (
      <OnboardingFirstUserLevel
        handleCheck={() => handleCheckOnboardingPanel('firstUserLevel')}
      />
    ) : null,

    onboardingCompleteBattery: hasOnboarding() ? (
      <OnboardingBatteryComplete
        handleCheck={() => handleCheckOnboardingPanel('firstBateryComplete')}
      />
    ) : null,

    onboardingChargedBattery: hasOnboarding() ? (
      <OnboardingBatteryCharged
        handleCheck={() => handleCheckOnboardingPanel('firstBateryCharged')}
      />
    ) : null,

    onboardingBrokenBattery: hasOnboarding() ? (
      <OnboardingBrokenBattery
        handleCheck={() => handleCheckOnboardingPanel('firstBrokenBatery')}
      />
    ) : null,

    rewardDailyGoal: <RewardDailyGoal handleCheck={checkPopup} />,

    rewardBatteryStatus: <RewardBatteryStatus handleCheck={checkPopup} />,

    rewardBatteryUnlock: <RewardBatteryUnlock handleCheck={checkPopup} />
  }

  // TODO: animación de entrada y salida
  // DONE: destruir listeners
  useEffect(() => {
    const handleLessonUnlocked = () =>
      pushOnboardingPanel('firstUnlockedLesson', 'onboardingLessonUnlocked')

    const handleChargedBattery = () =>
      pushOnboardingPanel('firstBateryCharged', 'onboardingChargedBattery')

    const handleCompleteBattery = () =>
      pushOnboardingPanel('firstBateryComplete', 'onboardingCompleteBattery')

    const handleBrokenBattery = () =>
      pushOnboardingPanel('firstBrokenBatery', 'onboardingBrokenBattery')

    const handleUnitUnlocked = () =>
      pushOnboardingPanel('firstUnlockedUnit', 'onboardingUnitUnlocked')

    const handleFirstLevelUser = () =>
      pushOnboardingPanel('firstUserLevel', 'onboardingFirstUserLevel')

    const handleFirstPiece = () =>
      pushOnboardingPanel('firstPiece', 'onboardingFirstPiece')

    const handleRewardDailyGoal = () => pushPopup('rewardDailyGoal')

    const handleRewardBatteryStatus = () => pushPopup('rewardBatteryStatus')

    const handleRewardBatteryUnlock = () => pushPopup('rewardBatteryUnlock')

    window.addEventListener('onboarding-lesson-unlocked', handleLessonUnlocked)

    window.addEventListener('onboarding-charged-battery', handleChargedBattery)

    window.addEventListener(
      'onboarding-complete-battery',
      handleCompleteBattery
    )

    window.addEventListener('onboarding-broken-battery', handleBrokenBattery)

    window.addEventListener('onboarding-unit-unlocked', handleUnitUnlocked)

    window.addEventListener('onboarding-first-user-level', handleFirstLevelUser)

    window.addEventListener('onboarding-first-piece', handleFirstPiece)

    window.addEventListener('reward-daily-goal', handleRewardDailyGoal)

    window.addEventListener('reward-battery-status', handleRewardBatteryStatus)

    window.addEventListener('reward-battery-unlock', handleRewardBatteryUnlock)

    return () => {
      window.removeEventListener(
        'onboarding-lesson-unlocked',
        handleLessonUnlocked
      )

      window.removeEventListener(
        'onboarding-charged-battery',
        handleChargedBattery
      )

      window.removeEventListener(
        'onboarding-complete-battery',
        handleCompleteBattery
      )

      window.removeEventListener(
        'onboarding-broken-battery',
        handleBrokenBattery
      )

      window.removeEventListener('onboarding-unit-unlocked', handleUnitUnlocked)

      window.removeEventListener(
        'onboarding-first-user-level',
        handleFirstLevelUser
      )

      window.removeEventListener('onboarding-first-piece', handleFirstPiece)

      window.removeEventListener('reward-daily-goal', handleRewardDailyGoal)

      window.removeEventListener(
        'reward-battery-status',
        handleRewardBatteryStatus
      )

      window.removeEventListener(
        'reward-battery-unlock',
        handleRewardBatteryUnlock
      )
    }
  }, [])

  useEffect(() => {
    // Mientras se muestra Popup las acciones en el mapa se detienen: movimiento, uso de "portales", etc.
    if (hasPopup() || showIntroduction()) {
      window.dispatchEvent(new CustomEvent('disable-game'))
    } else {
      window.dispatchEvent(new CustomEvent('enable-game'))
    }
  }, [onboarding, popupStore])

  // useEffect(() => {
  //   pushPopup('rewardDailyGoal')
  // }, [])

  return (
    <>
      {!!isDevMode && (
        <div className="onboarding__debug" onClick={resetOnboarding}></div>
      )}

      {hasPopup() || showIntroduction() ? (
        <div className="popup-manager">
          {hasPopup() ? (
            popupComponent[getFirstPopup()]
          ) : (
            <OnboardingIntroduction
              handleCheck={() => {
                handleCheckIntroduction()
                handleFinishIntro()
              }}
            />
          )}
        </div>
      ) : null}
    </>
  )
}

export default PopupManager
