import React from 'react'
import './contestPage.scss'
import logo from './assets/logo.svg'
import blueMonster from './assets/blueMonster.png'
import redMonster from './assets/redMonster.png'
import oneEyeGreenMonster from './assets/oneEyeGreenMonster.png'
import logoSimple from './assets/logotipo.png'
import instagram from './assets/instagramIcon.png'
import linkedin from './assets/linkedinIcon.png'
import youtube from './assets/youtubeIcon.png'
import planet from './assets/planet.png'

const ContestGraphicPage = () => {
  return (
    <div className="contest-info__page">
      <div className="contest-info__page-bg">
        <div className="content__container">
          <div className="content__header">
            <img
              className="content-graphic__logo"
              src={logo}
              alt="Blueberry Math"
            />
            <img
              className="content__red-monster pulsate-fwd"
              src={redMonster}
              alt="Red Monster"
            />
          </div>
          <div className="contest-graphic-page__title">
            ¡Ganará la clase que responda correctamente el mayor número de
            actividades!
          </div>
          <div className="content-graphic__highlight-text">
            Esta misión finaliza el 21 de junio a las 14.00h
          </div>
          <div className="contest__frame-container">
            <iframe
              className="contest__frame frame"
              src="https://app.amplitude.com/analytics/share/embed/16b9065c-ccad-43e2-9017-53b087794c5d "
              frameBorder="0"
              width="100%"
            ></iframe>
          </div>
          <img
            className="content__one-eye-green-monster pulsate-fwd"
            src={oneEyeGreenMonster}
            alt="Green Monster"
          />
        </div>
      </div>

      <div className="contents__footer">
        <div className="contents__footer-redes">
          <a
            href="https://www.instagram.com/blueberry_math/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="instagram" src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/blueberrymath/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="linkedin" src={linkedin} alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCdeFPf80P9ZRlaThqUf1cmg"
            target="_blank"
            rel="noreferrer"
          >
            <img className="youtube" src={youtube} alt="Youtube" />
          </a>
        </div>

        <div className="contents__footer-web">
          <img className="planetImage" src={planet} alt="Planet Image" />
          <a
            href="https://www.blueberrymath.ai/ "
            target="_blank"
            rel="noreferrer"
          >
            <p>Visita nuestra web</p>
          </a>
        </div>
        <div className="layouts-demo__footer-logo">
          <img src={logoSimple} alt="Blueberry" />
        </div>
        <img
          className="content__blue-monster pulsate-fwd"
          src={blueMonster}
          alt="Blue Monster"
        />
      </div>
    </div>
  )
}

export default ContestGraphicPage
