import React, { useEffect, useState } from 'react'
import { ReactComponent as SvgLoadingFrame_0 } from '../../../assets/loading/loading_0.svg'
import { ReactComponent as SvgLoadingFrame_1 } from '../../../assets/loading/loading_1.svg'
import './activityLoading.scss'

const ActivityLoading = ({ title, message }) => {
  const FRAME_MAX = 1
  let updater = null

  const [frameIndex, setFrameIndex] = useState(0)

  useEffect(() => {
    updateFrame()

    return () => {
      if (updater) clearTimeout(updater)
    }
  }, [frameIndex])

  const updateFrame = () => {
    updater = setTimeout(() => {
      let auxFrameIndex = frameIndex + 1
      if (auxFrameIndex > FRAME_MAX) auxFrameIndex = 0
      setFrameIndex(auxFrameIndex)
    }, 500)
  }

  return (
    <div className="activity-loading">
      {frameIndex === 0 && (
        <SvgLoadingFrame_0 className="activity-loading_frame" />
      )}
      {frameIndex === 1 && (
        <SvgLoadingFrame_1 className="activity-loading_frame" />
      )}
      {title && <div className="activity-loading_title">{title}</div>}
      {message && <div className="activity-loading_message">{message}</div>}
    </div>
  )
}

export default ActivityLoading
