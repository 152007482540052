import React, { useEffect } from 'react'
import { Analysis } from '../../services/analysisService'
import ContestInfoPage from './ContestInfoPage'
import ContestGraphicPage from './ContestGraphicPage'
import ContestResultPage from './ContestResultPage'

const ContestPage = () => {
  useEffect(() => {
    // Analítica: Contest Page Viewed → ¿Cuántas personas han visitado la página del concurso?
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_EVENTS['Contest Page Viewed']
    )
  })

  const CONTEST_PAGE = {
    INFO: 'INFO',
    GRAPHIC: 'GRAPHIC',
    RESULT: 'RESULT'
  }

  const currentPage = CONTEST_PAGE.RESULT

  return (
    <>
      {currentPage === CONTEST_PAGE.INFO && <ContestInfoPage />}
      {currentPage === CONTEST_PAGE.GRAPHIC && <ContestGraphicPage />}
      {currentPage === CONTEST_PAGE.RESULT && <ContestResultPage />}
    </>
  )
}

export default ContestPage
