import React, { useState, useEffect } from 'react'
import Title_battery from '../../assets/img/title_battery.svg'
import Title_shadow from '../../assets/img/title_shadow.svg'
import translate from '../../i18n/translate'
import { enableQAMode, isQAMode } from '../../services/qaModeService'
import { Analysis } from '../../services/analysisService'
import PracticeButton from '../AppPracticePage/modules/atoms/PracticeButton/PracticeButton'
import { isIosDevice, isAndroidDevice } from '../../util/mobile'
import {
  IOS_STORE_URL,
  ANDROID_INTENT_WITHOUT_PARAMS,
  ANDROID_INTENT_WITH_PARAMS
} from '../../config/constants/appSchemesAndStoreUrls'
import { getToken } from '../../services/util'
import AccessHelper from '../../services/util/accessHelper'
import { getCurrentLangString } from '../../i18n/initI18n'

const TitleScreenPage = () => {
  const [animationType, setAnimationType] = useState('bounceInDown')
  const [isLoading, setIsLoading] = useState(false)
  const [triggerQAModeCount, setTriggerQAModeCount] = useState(1)

  const [isIPhone, setIsIPhone] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const [ssoSource, setSsoSource] = useState('blueberrySso')

  useEffect(() => {
    const isiPhone = isIosDevice()
    const isAndroid = isAndroidDevice()

    setIsIPhone(isiPhone)
    setIsAndroid(isAndroid)

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Start,
      Analysis.SEGMENT_EVENTS['Start Page']
    )

    setTimeout(() => setAnimationType('pulse'), 2000)

    const search = window.location.search
    const [, params] = search.split('?')
    if (params) {
      const urlParams = new URLSearchParams(params)
      const ssoSourceParam = urlParams.get('ssoSource')
      setSsoSource(ssoSourceParam)
    }
  }, [])

  const goToMap = () => {
    Analysis.sendEvent(Analysis.EVENT.GO_TO_MAP_FROM_CUSTOM_SSO)

    setIsLoading(!isLoading)
    window.location.href = '/game'
  }

  const openApp = () => {
    const accessInformation = AccessHelper.getAccessInformation()

    if (isIPhone) {
      // This will redirect to App store
      let language = getCurrentLangString()
      if (language === 'es' || language === 'pt') {
        language = '/' + language
      } else language = ''

      window.location = IOS_STORE_URL.replace('[LANGUAGE]', language)
    } else if (isAndroid) {
      if (
        accessInformation &&
        accessInformation.accessType === AccessHelper.ACCESS_TYPE_BB_SSO
      ) {
        const intent = ANDROID_INTENT_WITH_PARAMS.replace('[SOURCE]', ssoSource)
          .replace('[TOKEN]', getToken())
          .replace('[HOST]', window.location.hostname)

        window.location = intent
      } else {
        window.location = ANDROID_INTENT_WITHOUT_PARAMS
      }
    }
  }

  const triggerQAMode = () => {
    setTriggerQAModeCount(triggerQAModeCount + 1)
    if (triggerQAModeCount === 5) {
      console.log('🙄 %c5 more...', 'color: blueviolet; font-weight:bold')
    }
    if (triggerQAModeCount === 10) {
      enableQAMode()
    }
    if (isQAMode()) {
      console.log('👻 %cGhost Mode', 'color: blueviolet; font-weight:bold')
    }
  }

  return (
    <div className="title-screen-page">
      <div className="fade-in">
        <div className={`title-screen-page__animation ${animationType}`}>
          <div className="title-screen-page__button-wrapper">
            <div className="title-screen-page__battery">
              <img
                className="title-screen-page__battery-img"
                src={Title_battery}
              />
              <img
                className={`title-screen-page__shadow-img ${
                  isQAMode() ? 'qa-mode-activated' : ''
                }`}
                src={Title_shadow}
                onClick={triggerQAMode}
              />
            </div>
            <div className={'title-screen-page__actions'}>
              <PracticeButton
                text={translate(
                  !isIPhone && !isAndroid
                    ? 'title_screen_button_start'
                    : 'title_screen_button_web'
                )}
                action={goToMap}
                type={1}
              />

              {isIPhone || isAndroid ? (
                <PracticeButton
                  text={translate('title_screen_button_app')}
                  action={openApp}
                  type={2}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleScreenPage
