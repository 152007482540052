import React from 'react'
import cn from '@aqrojo/class-names'
import ReactDOM from 'react-dom'
import translate from '../../../i18n/translate'
import useModalScreenPortraitTeacherPractice from './useModalScreenPortraitTeacherPractice'
import blueberroNoStroke from '../../../assets/img/blueberro_no_stroke.svg'

const drawerPortalId = '__blueberry-modal-container-teacher'

if (!document.getElementById(drawerPortalId)) {
  const div = document.createElement('div')
  div.id = drawerPortalId
  document.body.appendChild(div)
}

function ModalScreenPortraitTeacherPractice({
  className = '',
  open = false,
  showModal = false
}) {
  const { root, container, shadow } =
    useModalScreenPortraitTeacherPractice(showModal)

  return ReactDOM.createPortal(
    showModal && (
      <div ref={root} className={'modal-teacher ' + className}>
        <div ref={shadow} className="modal__shadow-layer" />
        {/* Pantalla de intro a la practica del profesor */}
        <div
          id="popupScreenTeacherPractice"
          className="modal__content-wrapper-init"
        >
          <div
            ref={container}
            className={cn('modal__content', {
              show: open,
              hide: !open
            })}
          >
            <div className="modal__content-wrapper-icon-error">
              <img src={blueberroNoStroke} alt="" />
            </div>
            <div className="modal__content-wrapper-init__container-text">
              <div className="modal__content-wrapper-init__title">
                {' '}
                {translate('teacher_practice_exit_title')}
              </div>
              <div className="modal__content-wrapper-init__subtitle">
                {translate('teacher_practice_error_portrait')}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    document.getElementById(drawerPortalId)
  )
}

export default ModalScreenPortraitTeacherPractice
