import React, { useState, useEffect } from 'react'
import Background1 from '../../assets/intro_bg_01.png'
import Background2 from '../../assets/intro_bg_02.png'
import Background3 from '../../assets/intro_bg_03.png'
import Background4 from '../../assets/intro_bg_04.png'
import Background5 from '../../assets/intro_bg_05.png'
import Background6 from '../../assets/intro_bg_06.png'

import OnboardingIntroductionPanel1 from './OnboardingIntroductionPanel1'
import OnboardingIntroductionPanel2 from './OnboardingIntroductionPanel2'
import OnboardingIntroductionPanel3 from './OnboardingIntroductionPanel3'
import OnboardingIntroductionPanel4 from './OnboardingIntroductionPanel4'
import OnboardingIntroductionPanel5 from './OnboardingIntroductionPanel5'

import { Analysis } from '../../../../../services/analysisService'
import MetaTabAvatar from '../../../MetaTabAvatar/MetaTabAvatar'

const OnboardingIntroduction = ({ handleCheck }) => {
  const [step, setStep] = useState(0)
  const [panel, setPanel] = useState(0)
  const [finalTransition, setFinalTransition] = useState(false)

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      // eslint-disable-next-line dot-notation
      Analysis.SEGMENT_EVENTS['Onboarding']
    )
    setTimeout(() => goToScreen(1), 1700)
  }, [])

  const goToScreen = (screenNumber, speed = 1700) => {
    setStep(screenNumber)
    setPanel(0)
    setTimeout(() => setPanel(screenNumber), speed)
  }

  const handleFinish = () => {
    setPanel(0)
    setFinalTransition(true)
    setTimeout(handleCheck, 2100)
  }

  return (
    <div
      className={`onboarding-intro ${finalTransition ? 'fade-out-final' : ''}`}
    >
      <div
        className={`onboarding-intro__background ${step === 3 ? 'shake' : ''}`}
      >
        <img
          src={Background1}
          alt=""
          className={`onboarding-intro__background-img ${
            step === 1 ? 'fade-in-start' : ''
          } ${step === 2 ? 'fade-out' : ''}`}
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 2 || step === 3 ? 'fade-in' : ''
          } ${step === 4 ? 'fade-out' : ''}`}
          src={Background2}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 3 ? 'fade-in-cross' : ''
          } ${step === 4 ? 'fade-out' : ''}`}
          src={Background3}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 4 ? 'fade-in' : ''
          } ${step === 5 ? 'fade-out' : ''}`}
          src={Background4}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 5 ? 'fade-in' : 'fade-out-final'
          }`}
          src={Background5}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 6 ? 'fade-in' : 'fade-out-final'
          }`}
          src={Background6}
          alt=""
        />
      </div>
      {panel === 1 && (
        <OnboardingIntroductionPanel1
          handleButton={() => goToScreen(2, 1000)}
        />
      )}
      {panel === 2 && (
        <OnboardingIntroductionPanel2 handleButton={() => goToScreen(3, 500)} />
      )}
      {panel === 3 && (
        <OnboardingIntroductionPanel3 handleButton={() => goToScreen(4)} />
      )}
      {panel === 4 && (
        <OnboardingIntroductionPanel4 handleButton={() => goToScreen(5)} />
      )}
      {panel === 5 && (
        <OnboardingIntroductionPanel5 handleButton={() => goToScreen(6, 0)} />
      )}
      {panel === 6 && (
        <div className="onboarding-intro__avatar-selector">
          <MetaTabAvatar isIntro={true} handleIntroContinue={handleFinish} />
        </div>
      )}
    </div>
  )
}
export default OnboardingIntroduction
