import React, { useEffect, useState } from 'react'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg'

import './errorMessage.scss'
// al pasar un error se muestra el componente
// al limpiar el error se oculta el componente
// al pulsar el botón de cerrar, se oculta el componente

function ErrorMessage({ error = '' }) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(error.length > 0)
  }, [error])

  return (
    <div className={`error-message ${visible ? 'visible' : ''}`}>
      <WarningIcon className="error-message__error-icon" />
      <div className="error-message__text">{error}</div>
      <WarningIcon className="error-message__error-icon" />
    </div>
  )
}

export default ErrorMessage
