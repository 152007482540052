import React, { useEffect, useState } from 'react'
import MetaberryView from '../../modules/views/MetaberryView/MetaberryView'
import './MetaBerryPage.scss'
import useMetaberryWithStore from '../../modules/views/MetaberryView/hooks/useMetaberryWithStore'
import { Analysis } from '../../services/analysisService'
import QuizLoading from '../TeacherPracticePage/components/QuizLoading/QuizLoading'
import { getUserAvatar } from '../../services/settingsService'
import LoadingPage from '../LoadingPage/LoadingPage'

const MetaberryPage = () => {
  const { availableUnits } = useMetaberryWithStore()
  // console.log('--- Metaberry PAGE availableUnits', availableUnits)

  const [userAvatarImg, setUserAvatarImg] = useState(
    getUserAvatar().navigation_icon_image
  )

  const [hasLoaded, setHasLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [progressBarCounter, setProgressBarCounter] = useState(0)

  useEffect(() => {
    handleLoadingStarted()
    // Temporal - para el cambio de urls de tener # a no tener y ser llamadas desde APPs
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ function: 'isNotPractice' })
      )
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Map Page']
    )
    window.addEventListener('player-avatar-change', updateAvatar)
    window.addEventListener('loading-started', handleLoadingStarted)
    window.addEventListener('loading-finished', handleLoadingFinished)
    window.addEventListener('increase-progress-bar', handleProgressBar)
    window.addEventListener('game-started', changeInitialLoadStatus)

    return () => {
      window.removeEventListener('player-avatar-change', updateAvatar)
      window.removeEventListener('loading-started', handleLoadingStarted)
      window.removeEventListener('loading-finished', handleLoadingFinished)
      window.removeEventListener('increase-progress-bar', handleProgressBar)
      window.removeEventListener('game-started', changeInitialLoadStatus)
    }
  }, [])

  const changeInitialLoadStatus = () => {
    setHasLoaded(true)
  }

  const updateAvatar = () => {
    setUserAvatarImg(getUserAvatar().navigation_icon_image)
  }

  const handleLoadingStarted = () => {
    if (!isLoading) {
      setIsLoading(true)
    }
  }

  const handleLoadingFinished = () => {
    setIsLoading(false)
  }
  const handleProgressBar = (event) => {
    setProgressBarCounter(event.detail.count)
  }

  return (
    <div className="metaberry-page">
      {/* <button onClick={() => methodDoesNotExist()}>Break the world</button>; */}
      <div className="metaberry-loading">
        {/* {!hasJustAccessed() ? <QuizLoading /> : <LoadingPage />} */}
        {isLoading &&
          (hasLoaded ? (
            <QuizLoading
              message=""
              goFullScreen={() => {}}
              exitFullScreen={() => {}}
              isFullscreen={true}
              isPlayerJoinGameStarted={false}
              userAvatarImg={userAvatarImg}
            />
          ) : (
            <LoadingPage count={progressBarCounter} />
          ))}
      </div>

      {availableUnits.length > 0 && <MetaberryView />}
    </div>
  )
}

export default MetaberryPage
