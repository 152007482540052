import React from 'react'
import PracticeButton from '../../atoms/PracticeButton/PracticeButton'
import { APP_ANALYSIS } from '../../../services/analysisServiceReactNative'
import { store } from '../../../../../store/configureStore'
import { ReactComponent as SvgHighlight } from '../../../assets/practice-complete/highlight-element.svg'
import { ReactComponent as SvgBatteryEmpty } from '../../../assets/batteries/battery-1-empty.svg'
import { ReactComponent as SvgBatteryLow } from '../../../assets/batteries/battery-2-low.svg'
import { ReactComponent as SvgBatteryMedium } from '../../../assets/batteries/battery-3-medium.svg'
// import { ReactComponent as SvgBatteryHigh } from '../../../assets/batteries/battery-4-high.svg'
import { ReactComponent as SvgBatteryFull } from '../../../assets/batteries/battery-5-full.svg'
import { ReactComponent as SvgBatteryBroken } from '../../../assets/batteries/battery-6-broken.svg'
import { ReactComponent as SvgRay } from '../../../assets/icons/lightning-bolt-yellow.svg'
import { ReactComponent as SvgGoldGear } from '../../../assets/earned-piece/gold-gear.svg'
import translate from '../../../../../i18n/translate'
import './practiceCompleteView.scss'

const PracticeCompleteView = ({
  practiceBaseData,
  setPracticeBaseData,
  practiceProgressData,
  setPracticeProgressData,
  setPracticeState,
  messageToReactNative,
  isIphone
}) => {
  let earnedPieces = 0
  let completeTitle = ''
  let masteredLessonMessage = ''
  let batteryIcon = null
  let showContinueButton = true

  // ------------------------------------------------------------------
  //      challenge   status
  // inicio            install / install
  // carga             charge  /  init
  // carga             charge  /  charging
  // todas las piezas  train   /  complete
  // rompe             train   /  broked_start
  // arregla           train   /  start
  const hasUserAllPieces = () => {
    return (
      practiceBaseData.userPieces[0] >= practiceBaseData.lessonPieces[0] &&
      practiceBaseData.userPieces[1] >= practiceBaseData.lessonPieces[1] &&
      practiceBaseData.userPieces[2] >= practiceBaseData.lessonPieces[2]
    )
  }

  const setMasteredLesson = () => {
    const wasBroken = practiceBaseData.lessonStatus === 'broked_start'
    completeTitle = translate(
      wasBroken
        ? 'app_practice_complete_title_repair'
        : 'app_practice_complete_title_mastered'
    )
    masteredLessonMessage = translate('app_practice_complete_message_mastered')

    // Si ha llenado toda la batería y tiene las piezas necesarias para que la siguiente esté desbloqueada
    if (hasUserAllPieces()) {
      showContinueButton = false
    }
  }

  // No uso useEffect para asignar los SVGs directamente sino tarda en cargar el icono y queda mal
  switch (practiceBaseData.updatedLessonStatus) {
    case 'install':
    case 'empty':
      completeTitle = translate('app_practice_complete_title_start')
      batteryIcon = (
        <SvgBatteryEmpty className="practice-complete-view__battery-icon" />
      )
      break

    case 'init':
      completeTitle = translate('app_practice_complete_title_start')
      batteryIcon = (
        <SvgBatteryLow className="practice-complete-view__battery-icon" />
      )
      break

    case 'charging':
      completeTitle = translate('app_practice_complete_title_charge')
      batteryIcon = (
        <SvgBatteryMedium className="practice-complete-view__battery-icon" />
      )
      break

    case 'complete':
      setMasteredLesson()
      batteryIcon = (
        <SvgBatteryFull className="practice-complete-view__battery-icon" />
      )
      break

    case 'broked_start':
      completeTitle = translate('app_practice_complete_title_still_broken')
      batteryIcon = (
        <SvgBatteryBroken className="practice-complete-view__battery-icon" />
      )
      break

    case 'start':
      setMasteredLesson()
      batteryIcon = (
        <SvgBatteryFull className="practice-complete-view__battery-icon" />
      )
      break
  }

  // Si era una lección dominada, se modifican los mensajes para advertir de la reducción de puntos ganados
  if (
    practiceBaseData.lessonStatus === 'start' &&
    practiceBaseData.lessonChallenge === 'train'
  ) {
    completeTitle = translate('app_practice_complete_title_mastered')
    masteredLessonMessage = translate('app_practice_complete_message_mastered')
  }

  // Para mostrar si hace falta la pieza que corresponda
  const earnedPiecesAux = practiceProgressData.earnedPieces.reduce(
    (total, current) => total + current,
    0
  )
  if (earnedPiecesAux > 0) {
    earnedPieces = earnedPiecesAux
  }

  // Para que al pedir el siguiente ejercicio sea del challenge
  const continuePracticing = () => {
    APP_ANALYSIS.sendAnalysisEventToReactNative(
      practiceBaseData.isFromApp,
      APP_ANALYSIS.APP_ANALYSIS_EVENT['Keep Practicing Button Clicked'],
      {
        practice_session_id: practiceProgressData?.sessionId,
        program_id: practiceBaseData.isFromApp
          ? 'from App'
          : store.getState()?.practice?.programId,

        unit_id: practiceBaseData?.unitGuid,
        lesson_id: practiceBaseData?.lessonGuid,
        lesson_status: practiceBaseData?.updatedLessonStatus
      }
    )

    // Reestablecer datos base de práctica
    setPracticeBaseData({
      ...practiceBaseData,
      lessonChallenge: practiceBaseData.updatedLessonChallenge,
      lessonChallengeExp: practiceBaseData.updatedLessonChallengeExp,
      lessonStatus: practiceBaseData.updatedLessonStatus,
      updatedLessonChallenge: undefined,
      updatedLessonChallengeExp: undefined,
      updatedLessonStatus: undefined
    })

    // Resetear datos de progreso de práctica
    setPracticeProgressData({
      sessionId: undefined,
      earnedExperience: 0,
      earnedExtraExperience: 0,
      earnedPieces: [0, 0, 0]
    })

    // Volver a la práctica
    setPracticeState(1)
  }

  const exitPractice = () => {
    APP_ANALYSIS.sendAnalysisEventToReactNative(
      practiceBaseData.isFromApp,
      APP_ANALYSIS.APP_ANALYSIS_EVENT['Exit Practice Button Clicked'],
      {
        practice_session_id: practiceProgressData?.sessionId,
        program_id: 'from App',
        unit_id: practiceBaseData?.unitGuid,
        lesson_id: practiceBaseData?.lessonGuid,
        lesson_status: practiceBaseData?.updatedLessonStatus
      }
    )

    if (practiceBaseData.isFromApp) {
      messageToReactNative({ function: 'onPracticeCompleted' })
    } else {
      window.dispatchEvent(new CustomEvent('exit-activities'))
    }
  }

  return (
    <div
      className={
        'practice-complete-view' +
        (!practiceBaseData.isFromApp ? ' web-version' : '') +
        (isIphone ? ' practice-complete-view_iPhone' : '')
      }
    >
      {/* Fondo */}
      <div className={'practice-complete-view__background'}>
        {practiceBaseData.isFromApp && (
          <div className={'practice-complete-view__ellipse-top'}></div>
        )}
        <div className={'practice-complete-view__triple-x-top'}></div>
        <div className={'practice-complete-view__vectors'}></div>
        <div className={'practice-complete-view__triangle'}></div>
        <div className={'practice-complete-view__function'}></div>
        <div className={'practice-complete-view__cradle'}></div>
        <div className={'practice-complete-view__triple-x-bottom'}></div>
        {practiceBaseData.isFromApp && (
          <div className={'practice-complete-view__ellipses-bottom'}></div>
        )}
      </div>

      {/* Contenido */}
      {practiceBaseData?.statusBarHeight ? (
        <div
          className="margin-for-status-bar"
          style={{
            width: '1px',
            height: practiceBaseData.statusBarHeight + 'px'
          }}
        ></div>
      ) : null}
      <div className={'practice-complete-view__content'}>
        <div className="practice-complete-view__message">
          <div className="practice-complete-view__message-title">
            {completeTitle}
          </div>
          <div className="practice-complete-view__message-experience">
            <span>
              {translate('app_practice_complete_experience', {
                experience: practiceProgressData.earnedExperience
              })}
            </span>
            <div className="practice-complete-view__message-ray">
              <SvgRay />
            </div>
          </div>
        </div>

        <div className="practice-complete-view__battery">
          <SvgHighlight className="practice-complete-view__battery-highlight" />
          {batteryIcon}
        </div>

        <div className="practice-complete-view__information">
          {masteredLessonMessage && (
            <div className="practice-complete-view__information_mastered-message">
              {masteredLessonMessage}
            </div>
          )}

          {practiceProgressData.earnedExtraExperience > 0 && (
            <div className="practice-complete-view__experience-extra">
              <span>{practiceProgressData.earnedExtraExperience}</span>
              <SvgRay />
              <span>{translate('app_practice_complete_experience_extra')}</span>
            </div>
          )}
          {earnedPieces > 0 && (
            <div className="practice-complete-view__earned-piece">
              <span>
                {translate(
                  earnedPieces > 1
                    ? 'app_practice_complete_pieces_multiple'
                    : 'app_practice_complete_pieces_single',
                  {
                    pieces: earnedPieces
                  }
                )}
              </span>
              <SvgGoldGear />
            </div>
          )}
        </div>

        <div className="practice-complete-view__action-buttons">
          <PracticeButton
            text={translate('app_practice_complete_exit_button')}
            action={exitPractice}
            type={
              practiceBaseData.isFromApp
                ? showContinueButton
                  ? 4
                  : 2
                : showContinueButton
                ? 2
                : 1
            }
          />
          {showContinueButton && (
            <PracticeButton
              text={translate('app_practice_complete_continue_button')}
              action={continuePracticing}
              type={practiceBaseData.isFromApp ? 2 : 1}
            />
          )}
          {/*
           1 morado
           2 blanco con borde
           3 blanco sin borde
           4 tranparente
           */}
        </div>
      </div>
    </div>
  )
}

export default PracticeCompleteView
