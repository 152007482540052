import { fetcher } from '../services/util'

let currentLang = {}
let langString = identifyLang(navigator.language)

let currentLangEs = {}
let currentLangEn = {}
let currentLangPt = {}

export function setCustomLanguageCode(languageCode) {
  langString = languageCode
}

export function identifyLang(testLang) {
  const storedLanguage = window.localStorage.getItem('bb_custom_language')
  if (storedLanguage && storedLanguage !== 'default') {
    return storedLanguage
  }

  const testLangCode = testLang.split('-')[0].toLowerCase() // Debería ser lowercase por defecto, pero no cuesta prevenir

  switch (testLangCode) {
    case 'es':
    case 'ca':
    case 'eu':
    case 'gl':
      return 'es'

    case 'pt':
      return 'pt'

    default:
      return 'en'
  }
}

// Para QA
window.identifyLang = () => identifyLang(navigator.language)

function loadLang(langString) {
  return fetcher(`./i18n/${langString.toLowerCase()}.json`)
    .then((baseData) => {
      return baseData
    })
    .catch((err) => console.error(err))
}

export async function initLang() {
  currentLangEs = await loadLang('es').then(
    (langData) => (currentLang = langData)
  )
  currentLangEn = await loadLang('en').then(
    (langData) => (currentLang = langData)
  )
  currentLangPt = await loadLang('pt').then(
    (langData) => (currentLang = langData)
  )

  document.documentElement.setAttribute('lang', langString)
  return loadLang(langString).then((langData) => (currentLang = langData))
}

export function getCurrentLang() {
  return currentLang
}

export function getCurrentLangString() {
  return langString
}

export function getCurrentLangEs() {
  return currentLangEs
}
export function getCurrentLangEn() {
  return currentLangEn
}
export function getCurrentLangPt() {
  return currentLangPt
}

export const translateWithLanguage = (text, language) => {
  if (!text) return ''

  let textsObject = {}
  switch (language) {
    case 'es':
      textsObject = getCurrentLangEs()
      break
    case 'en':
      textsObject = getCurrentLangEn()
      break
    case 'pt':
      textsObject = getCurrentLangPt()
      break

    default:
      textsObject = getCurrentLang()
      break
  }
  const translation =
    textsObject[text] !== undefined ? textsObject[text] : '__' + text

  return translation
}
