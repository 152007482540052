import React, { useEffect } from 'react'
import translate from '../../../../../i18n/translate'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../../../services/analysisService'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { getCurrentLangString } from '../../../../../i18n/initI18n'

const OnboardingIntroductionPanel2 = ({ handleButton }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'intro02_' + langCode

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_introduction_2',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <div className="onboarding-panel onboarding-panel--intro">
      {renderSoundFXController()}
      <div className="onboarding-panel__top"></div>
      <AudioBtn className="onboarding-panel__audio" onClick={handleAudio} />
      <div
        className="onboarding-panel__text"
        dangerouslySetInnerHTML={{
          __html: translate('onboarding_intro_text_2')
        }}
      ></div>
      <div
        className="onboarding-panel__button onboarding-panel__button--inline onboarding-panel__button--no-margin"
        onClick={handleButton}
      >
        {translate('onboarding_intro_button_2')}
      </div>
      <div className="onboarding-panel__bottom"></div>
    </div>
  )
}
export default OnboardingIntroductionPanel2
