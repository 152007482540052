import React from 'react'
import './contestPage.scss'
import logo from './assets/logo.svg'
import boyImage from './assets/boyImage.png'
import purplePlanet from './assets/purplePlanet.png'
import blueMonster from './assets/blueMonster.png'
import redMonster from './assets/redMonster.png'
import oneEyeGreenMonster from './assets/oneEyeGreenMonster.png'
import logoSimple from './assets/logotipo.png'
import instagram from './assets/instagramIcon.png'
import linkedin from './assets/linkedinIcon.png'
import youtube from './assets/youtubeIcon.png'
import planet from './assets/planet.png'

const ContestInfoPage = () => {
  return (
    <div className="contest-info__page">
      <div className="contest-info__page-bg">
        <div className="content__container">
          <div className="content__header">
            <img className="content__logo" src={logo} alt="Blueberry Math" />
            <img
              className="content__red-monster pulsate-fwd"
              src={redMonster}
              alt="Red Monster"
            />
          </div>
          <div className="contest-info-page__title">
            ¡Gana licencias gratuitas de Blueberry Math para tu clase!
          </div>
          <div className="content__highlight-text">
            El concurso comienza el próximo 17 de junio
          </div>
          <div className="content__info">
            Para que estas vacaciones de verano empiecen aún mejor, desde
            Blueberry Math queremos daros la oportunidad de ganar licencias
            gratuitas para el curso 2024/25.
          </div>
          <div className="content__images">
            <img
              className="content__boy-image"
              src={boyImage}
              alt="Boy with a computer"
            />
            <img
              className="content__purple-planet-image"
              src={purplePlanet}
              alt="Purple planet"
            />
          </div>
          <div className="contest__requirements-content">
            <img
              className="content__one-eye-green-monster pulsate-fwd"
              src={oneEyeGreenMonster}
              alt="Green Monster"
            />
            <div className="contest__requirement-title">
              ¿Qué necesitas saber?
            </div>
            <div className="contest__requirement-info">
              <p>
                <strong>Participantes:</strong> todos los niños y niñas de 1.º a
                6.º de Primaria que quieran divertirse y aprender.
              </p>
              <p>
                <strong>Fecha límite de inscripción: </strong>
                13 de junio.
              </p>
              <p>
                <strong>Duración: </strong>
                del 17 al 21 de junio, ambos inclusive.
              </p>
              <p>
                <strong>Horario: </strong>
                el profesor o tutor marcará el máximo de tiempo diario que el
                estudiante podrá jugar en clase o en casa.
              </p>
            </div>

            <div className="contest__requirement-info">
              <p>
                <strong>Información requerida:</strong> no se necesitan los
                datos personales de ningún alumno.
              </p>
              <p>
                <strong>Premio:</strong> una licencia gratuita de Blueberry Math
                para toda la clase durante el curso 2024/25.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="contents__footer">
        <div className="contents__footer-redes">
          <a
            href="https://www.instagram.com/blueberry_math/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="instagram" src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/blueberrymath/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="linkedin" src={linkedin} alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCdeFPf80P9ZRlaThqUf1cmg"
            target="_blank"
            rel="noreferrer"
          >
            <img className="youtube" src={youtube} alt="Youtube" />
          </a>
        </div>

        <div className="contents__footer-web">
          <img className="planetImage" src={planet} alt="Planet Image" />
          <a
            href="https://www.blueberrymath.ai/ "
            target="_blank"
            rel="noreferrer"
          >
            <p>Visita nuestra web</p>
          </a>
        </div>
        <div className="layouts-demo__footer-logo">
          <img src={logoSimple} alt="Blueberry" />
        </div>
        <img
          className="content__blue-monster pulsate-fwd"
          src={blueMonster}
          alt="Blue Monster"
        />
      </div>
    </div>
  )
}

export default ContestInfoPage
