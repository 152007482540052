import { useEffect, useState } from 'react'

import {
  getPopupStore,
  updatePopupStore
} from '../../../services/popupManagerService'

import {
  getOnboardingConfig,
  updateOnboardingConfig
} from '../../../services/settingsService'

import useLemonadeOnboarding from '../../../hooks/useLemonadeOnboarding'

export default function usePopupManager() {
  const [popupStore, setPopupStore] = useState(getPopupStore())
  const [onboarding, setOnboarding] = useState(getOnboardingConfig())
  const { lemonadeEmptyOnboarding } = useLemonadeOnboarding()

  useEffect(() => {
    // console.log('getconfig', getOnboardingConfig())
  }, [onboarding])

  function getFirstPopup() {
    const _tempStore = getPopupStore()
    // console.log('showFirstPopup', _tempStore)
    return _tempStore[0]
  }

  function hasPopup() {
    const _tempStore = getPopupStore()
    // console.log('hasPopup cola', _tempStore)
    return _tempStore.length > 0
  }

  function movePopupToLast(array, item) {
    if (array.includes(item)) {
      const index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        array.push(item)
      }
    }
    return array
  }

  function pushPopup(item) {
    let _tempStore = getPopupStore()
    if (!_tempStore.includes(item)) _tempStore.push(item)

    // El evento de primer onboarding debe ir antes que el de nuevas mascotas
    _tempStore = movePopupToLast(_tempStore, 'onboardingFirstUserLevel')

    updatePopupStore(_tempStore)
    setPopupStore(_tempStore)
  }

  function checkPopup() {
    const _tempStore = getPopupStore()
    _tempStore.shift()
    updatePopupStore(_tempStore)
    setPopupStore(_tempStore)
  }

  function resetPopupStore() {
    // console.log('resetPopupStore')
    const _tempStore = []
    updatePopupStore(_tempStore)
    setPopupStore(_tempStore)
  }

  function hasOnboarding() {
    const onBoarding = getOnboardingConfig()
    delete onBoarding.lemonade
    const onboardingValues = Object.values(onBoarding)
    return onboardingValues.includes(1)
  }

  function resetOnboarding() {
    const _onboardingConfig = getOnboardingConfig()
    Object.keys(_onboardingConfig).forEach(function (key) {
      _onboardingConfig[key] = 0
    })
    _onboardingConfig.introduction = 1
    _onboardingConfig.lemonade = lemonadeEmptyOnboarding
    updateOnboardingConfig(_onboardingConfig)
    setOnboarding(_onboardingConfig)
    resetPopupStore()
  }

  // Onboarding events handlers

  // El evento ha sucedido, se mostrará al volver al mapa
  function pushOnboardingPanel(type, popupId) {
    const _onboardingConfig = getOnboardingConfig()
    if (_onboardingConfig[type] === 0) {
      _onboardingConfig[type] = 1
      updateOnboardingConfig(_onboardingConfig)
      setOnboarding(_onboardingConfig)
      pushPopup(popupId)
    }
  }

  // Se ha mostrado y hemos cerrado el mensaje
  function handleCheckOnboardingPanel(type) {
    // console.log('CIERRA ONBOARDING: ', type)
    const _onboardingConfig = getOnboardingConfig()
    _onboardingConfig[type] = 2
    updateOnboardingConfig(_onboardingConfig)
    setOnboarding(_onboardingConfig)
    checkPopup()
  }

  function showIntroduction() {
    const _onboardingConfig = getOnboardingConfig()
    return _onboardingConfig.introduction === 1
  }

  function handleCheckIntroduction() {
    const _onboardingConfig = getOnboardingConfig()
    _onboardingConfig.introduction = 2
    updateOnboardingConfig(_onboardingConfig)
    setOnboarding(_onboardingConfig)
    // Muestra el onboarding de primeros movimientos
    pushOnboardingPanel('firstMoves', 'onboardingFirstMoves')
  }

  return {
    popupStore,
    resetPopupStore,
    getFirstPopup,
    pushPopup,
    checkPopup,
    hasPopup,
    onboarding,
    resetOnboarding,
    hasOnboarding,
    handleCheckIntroduction,
    pushOnboardingPanel,
    handleCheckOnboardingPanel,
    showIntroduction
  }
}
