import React, { useEffect, useState } from 'react'
import useQuery from '../../hooks/useQuery'
import ErrorImg from '../../assets/img/error_404.svg'
import translate from '../../i18n/translate'
import SimpleButton from '../../modules/atoms/SimpleButton/SimpleButton'
import { Analysis } from '../../services/analysisService'

const Error404Page = () => {
  const query = useQuery()
  const [showUserGoBack, setShowUserGoBack] = useState(false)

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      Analysis.SEGMENT_EVENTS['Error Page 404']
    )

    const _return = query.get('return')

    if (_return) {
      setShowUserGoBack(true)
    }
  }, [])

  const goBackToLogin = () => {
    window.location.href = '/login'
  }

  return (
    <div className="error-page">
      <div className="error-page__container">
        <div className="error-page__image">
          <img src={ErrorImg} alt="" />
        </div>
        <div className="error-page__title">{translate('error_404_title')}</div>
        <div className="error-page__subtitle">
          {translate('error_404_subtitle')}
        </div>
        {showUserGoBack && (
          <SimpleButton
            onClick={goBackToLogin}
            variant={'minimal'}
            text={translate('go_back')}
            color={'grey'}
            display="inline"
          />
        )}
      </div>
    </div>
  )
}

export default Error404Page
