import React, { useState } from 'react'

import OnboardingFirstMovesPanel1 from './OnboardingFirstMovesPanel1'
import OnboardingFirstMovesPanel2 from './OnboardingFirstMovesPanel2'

const OnboardingFirstMoves = ({ handleCheck }) => {
  const [step, setStep] = useState(1)

  const handleNextButton = () => setStep(2)

  return step === 1 ? (
    <OnboardingFirstMovesPanel1 handleButton={handleNextButton} />
  ) : (
    <OnboardingFirstMovesPanel2 handleButton={handleCheck} />
  )
}
export default OnboardingFirstMoves
