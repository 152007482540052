import React, { useCallback, useEffect, useState } from 'react'
import translate from '../../../../i18n/translate'
import InputWithLabel from '../../../LoginPage/components/InputWithLabel/InputWithLabel'

function EmailForm({ onSubmit, error, validateEmailFormat, disabled }) {
  const [email, setEmail] = useState('')
  const isFormError = error ? 'error' : ''

  useEffect(() => {
    validateEmailFormat(email)
  }, [email])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit({ email })
    },
    [onSubmit, email]
  )

  return (
    <div className={'email-form ' + isFormError}>
      <h2 className="form-title">{translate('recover_password_title')}</h2>
      <div className="form-text">{translate('recover_password_text')}</div>
      <form>
        <div className="form-group">
          <InputWithLabel
            id="email"
            label={translate('family_signup_signup_email_label')}
            value={email}
            isError={isFormError}
            onChange={setEmail}
            inputType="email"
            placeholder={translate('family_signup_signup_email_placeholder')}
          />
          {error.length > 0 && <div className="form-input-error">{error}</div>}
        </div>

        <div className="submit-control">
          <button
            type="submit"
            className="btn"
            onClick={handleSubmit}
            disabled={isFormError || disabled || email === ''}
          >
            {translate('recover_password_send')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default EmailForm
