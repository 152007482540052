import React from 'react'
import { ReactComponent as SvgBackground } from '../../../assets/earned-piece/earned-piece-background.svg'
import { ReactComponent as SvgGoldGear } from '../../../assets/earned-piece/gold-gear.svg'
import translate from '../../../../../i18n/translate'
import './earnedPiece.scss'

const EarnedPiece = ({ setShow }) => {
  const hidePopup = () => {
    setShow(false)
  }

  return (
    <div className="earned-piece" onClick={hidePopup}>
      <div className="earned-piece_frame">
        <SvgBackground className="earned-piece_background" />
        <div className="earned-piece_content">
          <div className="earned-piece_gear">
            <SvgGoldGear />
          </div>
          <div className="earned-piece_text">
            {translate('app_practice_earned_piece_popup')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarnedPiece
