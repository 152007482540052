import React, { useEffect, useState } from 'react'
import useQuery from '../../hooks/useQuery'
import ErrorImg from '../../assets/img/error_500.svg'
import translate from '../../i18n/translate'
import SimpleButton from '../../modules/atoms/SimpleButton/SimpleButton'
import { Analysis } from '../../services/analysisService'
import { clearToken, getToken } from '../../services/util'
import { clearQAMode } from '../../services/qaModeService'
import { sendIssuesAutomaticReport } from '../AppPracticePage/services/practiceServicesReactNative'
import { getWhoAmI } from '../../services/courseInfoService'
import { isAndroidDevice, isIosDevice } from '../../util/mobile'
import Error108Img from '../../assets/img/blueberro-no-stroke-new.svg'

const ErrorUserContentPage = () => {
  const query = useQuery()
  const [showUserGoBack, setShowUserGoBack] = useState(false)
  const [isReportSended, setIsReportSended] = useState(false)

  const [title, setTitle] = useState(translate('error_user_content_page_title'))
  const [subTitle, setSubTitle] = useState(
    translate('error_user_content_page_message')
  )
  const [icon, setIcon] = useState(ErrorImg)

  useEffect(() => {
    const errorPath = window.location.pathname.substring(1)
    const errorString = errorPath.replace('error-', '')

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      'Error Page ' + errorString
    )

    const _return = query.get('return')

    if (_return) {
      setShowUserGoBack(true)
    }

    if (errorString === 'BBE-108') {
      setTitle(translate('error_user_content_page_title_no_active'))
      setSubTitle(translate('error_user_content_page_message_no_active'))
      setIcon(Error108Img)
    }

    if (errorString !== 'BBE-108' && getToken()) {
      sendErrorNotification(errorString)
    } else {
      setIsReportSended(true)
    }
  }, [])

  const sendErrorNotification = async (error) => {
    const playerWhoAmI = await getWhoAmI()
    const playerGuid = playerWhoAmI.guid
    let userEmail = playerWhoAmI.email
    // Si es hijo y no tiene email, se pide al padre
    if (!playerWhoAmI.userEmail) {
      const mainWhoAmI = await getWhoAmI(true)
      userEmail = mainWhoAmI.email
    }

    let errorComment = ''
    if (isIosDevice()) errorComment = 'On iOS.'
    else if (isAndroidDevice()) errorComment = 'On Android.'
    else errorComment = 'On desktop.'

    switch (error) {
      case 'BBE-100':
        errorComment += ' No course.'
        break
      case 'BBE-101':
        errorComment += ' There are no unit items.'
        break
      case 'BBE-102':
        errorComment += ' There are no lesson items.'
        break
      case 'BBE-103':
        errorComment += ' There is no object on the map for unit items.'
        break
      case 'BBE-104':
        errorComment += ' There is no object on the map for lesson items.'
        break
      case 'BBE-105':
        errorComment +=
          ' There are not enough objects on the map for the unit items.'
        break
      case 'BBE-106':
        errorComment +=
          ' There are not enough objects on the map for the lessons items.'
        break
      case 'BBE-107':
        errorComment += ' Unknown portal.'
        break
    }

    await sendIssuesAutomaticReport({
      userEmail,
      playerGuid,
      issues: [error],
      comment: errorComment,
      userAgent: window.navigator.userAgent
    })

    clearQAMode()
    clearToken()
    setIsReportSended(true)
  }

  const goBackToLogin = () => {
    window.location.href = '/login'
  }

  return (
    <div className="error-page">
      <div className="error-page__container">
        <div className="error-page__image">
          {icon && <img src={icon} alt="" />}
        </div>
        <div className="error-page__title">{title}</div>
        <div
          className="error-page__subtitle"
          dangerouslySetInnerHTML={{
            __html: subTitle
          }}
        ></div>
        {showUserGoBack && (
          <SimpleButton
            onClick={goBackToLogin}
            variant={'minimal'}
            text={translate('go_back')}
            color={'grey'}
            display="inline"
            disabled={!isReportSended}
          />
        )}
      </div>
    </div>
  )
}

export default ErrorUserContentPage
