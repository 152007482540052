import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ReactComponent as SvgCorrect_0 } from '../../../assets/blueberrians/correct_0.svg'
import { ReactComponent as SvgCorrect_1 } from '../../../assets/blueberrians/correct_1.svg'
import { ReactComponent as SvgCorrect_2 } from '../../../assets/blueberrians/correct_2.svg'
import { ReactComponent as SvgIncorrect_0 } from '../../../assets/blueberrians/incorrect_0.svg'
import { ReactComponent as SvgIncorrect_1 } from '../../../assets/blueberrians/incorrect_1.svg'
import { ReactComponent as SvgIncorrect_2 } from '../../../assets/blueberrians/incorrect_2.svg'
import { FEEDBACK_TYPE } from '../../../constants/FeedbackConstants'
import './feedbackIndicator.scss'

const FeedbackIndicator = ({ message, type, variant }) => {
  const [IconComponent, setIconComponent] = useState(null)

  useEffect(() => {
    const ICONS = {
      [FEEDBACK_TYPE.CORRECT.toLowerCase()]: [
        SvgCorrect_0,
        SvgCorrect_1,
        SvgCorrect_2
      ],
      [FEEDBACK_TYPE.INCORRECT.toLowerCase()]: [
        SvgIncorrect_0,
        SvgIncorrect_1,
        SvgIncorrect_2
      ]
    }

    // Aquí no invocamos la función, en su lugar, solo la referenciamos
    setIconComponent(() => ICONS[type][variant])
  }, [type, variant])

  return (
    <div className={`feedback-indicator feedback-indicator--${type}`}>
      {IconComponent && <IconComponent />}
      {message}
    </div>
  )
}

FeedbackIndicator.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
}

export default FeedbackIndicator
