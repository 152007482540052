// Popup Manager Service

export function updatePopupStore(popupStore) {
  window.localStorage.setItem('bb_popupStore', JSON.stringify(popupStore))
}

export function getPopupStore() {
  return JSON.parse(window.localStorage.getItem('bb_popupStore')) || []
}

export function updateBatteryChange(data) {
  window.localStorage.setItem('bb_batteryChange', JSON.stringify(data))
}

export function getBatteryChange() {
  return JSON.parse(window.localStorage.getItem('bb_batteryChange')) || []
}

export function updateBatteryPieces(data) {
  window.localStorage.setItem('bb_batteryPieces', JSON.stringify(data))
}

export function getBatteryPieces() {
  return JSON.parse(window.localStorage.getItem('bb_batteryPieces')) || []
}

// export function getPanelRenderCount() {
//   const settings = store.getState().settings
//   return settings.panelRenderCount
// }

// export function updatePanelRenderCount(panelRenderCount) {
//   const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
//   settings.panelRenderCount = panelRenderCount
//   store.dispatch(setSettings(settings))
//   window.localStorage.setItem('bb_settings', JSON.stringify(settings))
// }
