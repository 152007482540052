import React, { useEffect } from 'react'
import ErrorImg from '../../assets/img/error_405.svg'
import translate from '../../i18n/translate'
import { clearToken } from '../../services/util'
import SimpleButton from '../../modules/atoms/SimpleButton/SimpleButton'
import { Analysis } from '../../services/analysisService'

const ErrorLicenseExpired = () => {
  // silent logout for user, silent means no redirect to login page
  // This is to allow the user to go to login page on refresh because the page does not have any actions
  const logout = () => {
    clearToken()
    window.localStorage.clear()
  }

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      Analysis.SEGMENT_EVENTS.ErrorLicenseExpired
    )

    logout()
  }, [])

  const goBackToLogin = () => {
    window.location.href = '/login'
  }

  return (
    <div className="error-page">
      <div className="error-page__container">
        <div className="error-page__image">
          <img src={ErrorImg} alt="" />
        </div>
        <div className="error-page__title">
          {translate('error_license_expired_title')}
        </div>
        <div className="error-page__subtitle">
          {translate('error_license_expired_subtitle')}
        </div>

        <SimpleButton
          onClick={goBackToLogin}
          variant={'minimal'}
          text={translate('go_back')}
          color={'grey'}
          display="inline"
        />
      </div>
    </div>
  )
}

export default ErrorLicenseExpired
