import React from 'react'
import './AvatarData.scss'
const AvatarData = ({ title, description }) => {
  return (
    <div className="avatar-data-container">
      <div className="avatar-data-container__title">{title}</div>
      <div className="avatar-data-container__description">
        <div className="avatar-data-container__description-text">
          {description}
        </div>
      </div>
    </div>
  )
}

export default AvatarData
