import React from 'react'
import './mapItemsCounter.scss'

import translate from '../../../i18n/translate'
import MapBarTooltip from '../MapBarTooltip/MapBarTooltip'
import { ReactComponent as CoinSvg } from '../../../assets/img/metaberry_ui/coin.svg'
import { ReactComponent as GearSvg } from '../../../assets/img/metaberry_ui/gold-gear.svg'

const MapItemsCounter = ({ icon, count }) => {
  const ICON_COMPONENTS = {
    coin: <CoinSvg />,
    gear: <GearSvg />
  }

  const ICON_MESSAGES = {
    coin: translate('info_tooltip_coins'),
    gear: translate('info_tooltip_pieces')
  }

  return (
    <>
      <MapBarTooltip
        width="265px"
        arrowPosition={icon === 'gear' ? 'left' : 'right'}
        message={ICON_MESSAGES[icon]}
      >
        <div className="map-items-counter">
          <div className="map-items-counter__background">
            <div className="map-items-counter__icon">
              {ICON_COMPONENTS[icon]}
            </div>
            <div className="map-items-counter__count">{count}</div>
          </div>
        </div>
      </MapBarTooltip>
    </>
  )
}

export default MapItemsCounter
