import React from 'react'
import { APP_ANALYSIS } from '../../../services/analysisServiceReactNative'
import { store } from '../../../../../store/configureStore'
import { changeDevMode } from '../../../../../store/Configuration/Configuration'
import './activityHeader.scss'
import SvgLihtningBolt from '../../../assets/icons/lightning-bolt-blue.svg'
import PracticeButton from '../../atoms/PracticeButton/PracticeButton'
import { ReactComponent as SvgChevronLeft } from '../../../assets/icons/chevron-left.svg'
import { ReactComponent as SvgIssue } from '../../../assets/icons/flag.svg'

const ActivityHeader = ({
  messageToReactNative,
  barMax,
  barCurrent,
  isPractice,
  setShowAbortPracticePopup,
  currentExercise,
  previousSeedId,
  numberExercisesCorrect,
  numberExercisesIncorrect,
  practiceStartedDate,
  setShowIssueReportPopup,
  isFromApp
}) => {
  const forcedCloseExecise = () => {
    if (barCurrent > 0) {
      setShowAbortPracticePopup(true)
    } else {
      APP_ANALYSIS.sendAnalysisEventToReactNative(
        isFromApp,
        APP_ANALYSIS.APP_ANALYSIS_EVENT['Practice Session Aborted'],
        {
          practice_session_id: currentExercise?.session_id,
          program_id: isFromApp
            ? 'from App'
            : store.getState()?.practice?.programId,
          unit_id: currentExercise?.unit_guid,
          lesson_id: currentExercise?.lesson_guid,
          current_seed_id: currentExercise?.question?.seed_guid,
          previous_seed_id: previousSeedId,
          number_activities_correct: numberExercisesCorrect,
          number_activities_incorrect: numberExercisesIncorrect,
          time_taken: new Date() - practiceStartedDate,
          current_session_points: barCurrent
        }
      )

      if (isFromApp) {
        messageToReactNative({ function: 'onPracticeAborted' })
      } else {
        window.dispatchEvent(new CustomEvent('exit-activities'))
      }
    }
  }

  const getBarPercentage = () => {
    if (!barCurrent || !barMax) return 0

    let percentage = (barCurrent / barMax) * 100

    if (percentage < 0) percentage = 0
    if (percentage > 100) percentage = 100

    return percentage + '%'
  }

  const openIssueReportPopup = () => {
    setShowIssueReportPopup(true)
  }

  const toogleDevMode = () => {
    if (store.getState().configuration.canUseDevMode) {
      store.dispatch(changeDevMode())
    }
  }

  return (
    <div className="activity-header">
      {/* Botón salir */}
      <PracticeButton
        icon={<SvgChevronLeft />}
        type={3}
        action={forcedCloseExecise}
        disabled={!isPractice}
      />

      {/* Barra de experiencia */}
      <div className="activity-header__experience">
        <img
          src={SvgLihtningBolt}
          alt="⭍"
          draggable="false"
          onClick={toogleDevMode}
        />
        <div className="activity-header__bar-container">
          <div
            className="activity-header__bar-content"
            style={{ width: getBarPercentage() }}
          ></div>
        </div>
      </div>

      {/* Botón reportar errores */}
      <div className="activity-header__issue-report">
        <PracticeButton
          icon={<SvgIssue />}
          type={3}
          action={openIssueReportPopup}
        />
      </div>
    </div>
  )
}

export default ActivityHeader
