import React from 'react'
import translate from '../../../i18n/translate'
import MetaOptionsMenuToast from '../MetaOptionsMenuToast/MetaOptionsMenuToast'
import MapLevelCounter from '../MapLevelBadge/components/MapLevelCounter/MapLevelCounter'
import { ReactComponent as CoinSvg } from '../../../assets/img/metaberry_ui/coin.svg'

const PopupSelector = ({
  isIntro,
  handleMainButton,
  isLoading,
  showToast,
  content,
  type,
  typePopupSelector,
  showMainButton,
  textMainButton,
  level,
  coin = 0
}) => {
  return (
    <div className="meta-popup-selector">
      {showToast && (
        <div className="meta-popup-selector__toast">
          <MetaOptionsMenuToast
            text={
              type === typePopupSelector.PETS
                ? translate('saved_pet_toast_message')
                : translate('saved_avatar_toast_message')
            }
          />
        </div>
      )}

      <div className="meta-popup-selector__header">
        <div className="meta-popup-selector__header-top">
          <div className="meta-popup-selector__header-left">
            <div
              className={`meta-popup-selector__header-left__content ${
                type === typePopupSelector.PETS &&
                'meta-popup-selector__header-left__content-big'
              }`}
            >
              {(type === typePopupSelector.AVATAR && !isIntro) ||
              type === typePopupSelector.PETS ? (
                <>
                  <div className="meta-popup-selector__header-left__content-level">
                    <div className="meta-popup-selector__header-left__content-level-text">
                      {translate('level')}
                    </div>
                    <MapLevelCounter level={level} />
                  </div>
                  {type === typePopupSelector.PETS && (
                    <div className="meta-popup-selector__header-left__content-coins">
                      <div className="meta-popup-selector__header-left__content-coins-text">
                        {coin}
                      </div>
                      <div className="meta-popup-selector__header-left__content-coins-icon">
                        <CoinSvg />
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>

          <div className="meta-popup-selector__header-center">
            <div className="meta-popup-selector__title">
              {type === typePopupSelector.AVATAR
                ? translate('avatar_title')
                : translate('pets_title')}
            </div>
          </div>

          <div className="meta-popup-selector__header-right">
            <div
              className={
                isIntro
                  ? 'meta-popup-selector__header-right__content'
                  : 'meta-popup-selector__header-right__content-empty'
              }
            ></div>
          </div>
        </div>
      </div>

      <div className="meta-popup-selector__content">
        {!isLoading && (
          <>
            <div className="meta-popup-selector__grid">{content}</div>
          </>
        )}
        {showMainButton && (
          <div
            className={`meta-popup-selector__button-container ${
              isIntro && 'meta-popup-selector__button-container-intro'
            }`}
          >
            <div
              className="meta-popup-selector__button"
              onClick={handleMainButton}
            >
              {textMainButton}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PopupSelector
