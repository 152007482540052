import React from 'react'
import cn from '@aqrojo/class-names'
import ReactDOM from 'react-dom'
import translate from '../../../i18n/translate'
import useModalTeacherPractice from './useModalTeacherPractice'
import spaceRocket from '../../../assets/img/spaceRocket2.png'
import { ReactComponent as IconClose } from '../../../assets/icons/icon_xclose.svg'

const drawerPortalId = '__blueberry-modal-container-teacher'

if (!document.getElementById(drawerPortalId)) {
  const div = document.createElement('div')
  div.id = drawerPortalId
  document.body.appendChild(div)
}

function ModalTeacherPractice({
  className = '',
  open = false,
  onCloseStart = () => {},
  onCloseComplete = () => {},
  showModal = false,
  startHandle = () => {},
  insideLobby = false,
  insidePractice = false,
  withCloseButton = false
}) {
  const { root, container, shadow } = useModalTeacherPractice(
    showModal,
    startHandle,
    onCloseComplete
  )

  return ReactDOM.createPortal(
    showModal && !insideLobby && !insidePractice && (
      <div ref={root} className={'modal-teacher ' + className}>
        <div ref={shadow} className="modal__shadow-layer" />
        {/* Pantalla de intro a la practica del profesor */}
        <div
          id="popupInitTeacherPractice"
          className="modal__content-wrapper-init"
        >
          <div
            ref={container}
            className={cn('modal__content', {
              show: open,
              hide: !open
            })}
          >
            <div className="modal__content-wrapper-icon">
              <img src={spaceRocket} alt="" />
            </div>
            <div className="modal__content-wrapper-init__container-text">
              <div className="modal__content-wrapper-init__title">
                {' '}
                {translate('teacher_practice_init_title')}
              </div>
              <div className="modal__content-wrapper-init__subtitle">
                {translate('teacher_practice_init_subtitle')}
              </div>
            </div>

            <button
              className="modal__content-wrapper-init__button"
              onClick={onCloseComplete}
            >
              {translate('teacher_practice_init_button')}
            </button>

            {withCloseButton && (
              <button className="modal__close-button" onClick={onCloseStart}>
                <IconClose className="iconClose" />
              </button>
            )}
          </div>
        </div>
      </div>
    ),
    document.getElementById(drawerPortalId)
  )
}

export default ModalTeacherPractice
