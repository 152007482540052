const serverPath = 'https://blueberry.tangerine-dev1.oneclicklabs.es/api' // School
// const serverPath = 'https://blueberry-family-dev.oneclicklabs.es/api' // Family

export default function createEndPoint(endPoint) {
  const path = endPoint ? `/${endPoint}` : ''
  const { origin } = window.location

  //*
  return origin.match(/http:\/\/localhost:/)
    ? serverPath + path
    : `${origin}/api` + path
  // */

  /* para browser stack con iphone/ipad
  return origin.match(/http:\/\/bs-local.com:/)
    ? serverPath + path
    : `${origin}/api` + path
  // */
}
