import React, { useEffect } from 'react'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import translate from '../../../../../i18n/translate'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import FirstMovesAnimation from '../../assets/first_moves.gif'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'

const OnboardingFirstMovesPanel1 = ({ handleButton }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'onBoardingFirstMoves01_' + langCode

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_first_moves_1',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <>
      <div className="onboarding-panel__backdrop" onClick={handleButton}></div>
      <div className="onboarding-panel centered">
        {renderSoundFXController()}
        <div className="onboarding-panel-large__top"></div>
        <AudioBtn
          className="onboarding-panel__audio left"
          onClick={handleAudio}
        />
        <div className="onboarding-panel__close-icon" onClick={handleButton}>
          <CloseIcon />
        </div>

        <div className="onboarding-panel__text large">
          <p>{translate('onboarding_first_moves_text_1')}</p>
        </div>

        <img
          className="onboarding-panel__radius-image"
          src={FirstMovesAnimation}
          alt=""
        />

        <div className="onboarding-panel__button" onClick={handleButton}>
          {translate('onboarding_first_moves_button_1')}
        </div>

        <div className="onboarding-panel-large__bottom"></div>
      </div>
    </>
  )
}
export default OnboardingFirstMovesPanel1
