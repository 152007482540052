// Imports
import 'react-lemonade-editor/dist/index.css'
import './assets/styles/index.scss'

import { setBBPracticeDefaultConfig } from './utils/practiceConfig'
import printModuleVersion from './utils/printModuleVersion'
// import { setLemonadeDefaultConfig } from './utils/setLemonadeConfig'

// Exports
export { ACTIVITY_TYPES } from './constants/ActivityConstants'
export { default as ActivityBlueberryComponent } from './pages/ActivityBlueberryComponent/ActivityBlueberryComponent'

// Init operations
setBBPracticeDefaultConfig()
// setLemonadeDefaultConfig()
printModuleVersion('2.0.1')
