import {
  createEndPoint,
  fetchWithToken,
  getToken
} from '../../../services/util'

// Servicios para APP (React Native)
let ReactNativeAppHost = ''
let ReactNativeAppToken = ''

/*
export const getWebProgramLanguage = () => {
  let programLanguage = 'EN'

  try {
    programLanguage = JSON.parse(
      window.localStorage.getItem('bb_tts')
    ).programLanguage
  } catch (e) {}

  return programLanguage.toUpperCase()
}
*/

export const setAppHostReactNative = (appHost) => {
  ReactNativeAppHost = appHost
}

export const getAppHostReactNative = () => {
  return ReactNativeAppHost
}

export const setAppTokenReactNative = (userToken) => {
  ReactNativeAppToken = userToken
}

export const getAppTokenReactNative = () => {
  return ReactNativeAppToken
}

export const getFirstExercise = (
  courseGuid,
  lessonGuid,
  challenge,
  language,
  isFromApp
) => {
  if (isFromApp) {
    return getFirstExerciseApp(
      courseGuid,
      lessonGuid,
      challenge,
      language.toUpperCase()
    )
  } else {
    return getFirstExerciseWeb(
      courseGuid,
      lessonGuid,
      challenge,
      language.toUpperCase() // getWebProgramLanguage()
    )
  }
}

const getFirstExerciseApp = async (
  courseGuid,
  lessonGuid,
  challenge,
  language
) => {
  const endPoint =
    ReactNativeAppHost +
    `/app/blueberry/courses/${courseGuid}/lessons/${lessonGuid}/nodes?challenge=${challenge}&lang=${language}`

  return fetch(endPoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${ReactNativeAppToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((response) => response.json())
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((e) => {
      throw e
    })
}

async function getFirstExerciseWeb(
  courseGuid,
  lessonGuid,
  challenge,
  language
) {
  const endPoint = `lms/courses/${courseGuid}/blueberry/lessons/${lessonGuid}/nodes?challenge=${challenge}&lang=${language}`
  return fetchWithToken(createEndPoint(endPoint))
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((e) => {
      throw e
    })
}

export const sendUserAnswer = async ({
  responseValue,
  nodeGuid,
  userResponse,
  language,
  isScaffolding,
  isFromApp
}) => {
  const endPoint = isFromApp
    ? ReactNativeAppHost +
      `/app/blueberry/nodes/${nodeGuid}?lang=${language.toUpperCase()}`
    : createEndPoint(
        // `blueberry/nodes/${nodeGuid}?lang=${getWebProgramLanguage()}`
        `blueberry/nodes/${nodeGuid}?lang=${language.toUpperCase()}`
      )

  return fetch(endPoint, {
    method: 'PUT',
    headers: {
      Authorization: isFromApp ? `Bearer ${ReactNativeAppToken}` : getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      is_correct: responseValue ? 1 : 0,
      response: userResponse,
      isScaffolding: isScaffolding
    })
  })
    .then((response) => response.json())
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((e) => {
      throw e
    })
}

export const sendIssuesUserReport = async ({
  userEmail,
  playerGuid,
  seedName,
  issues,
  comment,
  isFromApp,
  lessonGuid,
  userAgent,
  screenshot
}) => {
  const endPoint = isFromApp
    ? ReactNativeAppHost + '/app/blueberry/notifications/notify-error'
    : createEndPoint('blueberry/notifications/notify-error')

  return fetch(endPoint, {
    method: 'POST',
    headers: {
      Authorization: isFromApp ? `Bearer ${ReactNativeAppToken}` : getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userEmail,
      playerGuid,
      seedName,
      issues,
      comment,
      lessonGuid,
      userAgent,
      isFromApp,
      screenshot
    })
  })
    .then((response) => response.json())
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((e) => {
      throw e
    })
}

export const sendIssuesAutomaticReport = async ({
  userEmail,
  playerGuid,
  issues,
  comment,
  userAgent
}) => {
  const endPoint = createEndPoint('blueberry/notifications/notify-error')

  return fetch(endPoint, {
    method: 'POST',
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userEmail,
      playerGuid,
      issues,
      comment,
      userAgent
    })
  })
    .then((response) => response.json())
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((e) => {
      throw e
    })
}

/* Establecer start_at en actividades, para asegurar que en los reportes
     sólo se tengan en cuenta las actividades vistas por el usuario y no todas las obtenidas.
     Con esto se indica a API que se muestra actividad a estudiante.
  */
export const notifyQuestionViewedByUser = async (nodeGuid, isFromApp) => {
  const endPoint = isFromApp
    ? ReactNativeAppHost +
      `/app/blueberry/nodes/${nodeGuid}?action=viewQuestion`
    : createEndPoint(`blueberry/nodes/${nodeGuid}?action=viewQuestion`)

  return fetch(endPoint, {
    method: 'PUT',
    headers: {
      Authorization: isFromApp ? `Bearer ${ReactNativeAppToken}` : getToken(),
      'Content-Type': 'application/json'
    }
  })
    .then((response) => response.json())
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((e) => {
      throw e
    })
}
