import { store } from '../store/configureStore'
import { createEndPoint, fetcher, getToken } from './util'
import {
  setSettings,
  setSoundAssistance
} from '../store/SettingsSlice/SettingsSlice'
import { getMainCourseData } from '../assets/data/api'

export function getStoredSettings() {
  let localSettings = window.localStorage.getItem('bb_settings')

  if (localSettings) {
    localSettings = JSON.parse(window.localStorage.getItem('bb_settings'))
  }

  return localSettings
}

export function getSettings(_token, forceMainUser) {
  const endPoint = `users/settings`
  const token = forceMainUser
    ? getToken(true)
    : _token !== undefined
    ? _token
    : getToken()

  return fetcher(createEndPoint(endPoint), {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  })
}

export function saveSettings(settingsData) {
  const token = getToken()
  if (!token) {
    return null
  }

  return fetcher(createEndPoint('users/settings'), {
    method: 'PUT',
    body: JSON.stringify(settingsData),
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  }).then((json) => {
    return json.data
  })
}

export function updateSettings(settingsData, hasToSave) {
  // Se comprueba si hubo refresh para manter esa posición ya que no da tiempo a mandar al servidor en este caso
  let storedSettings = window.localStorage.getItem('bb_settings')
  storedSettings = storedSettings ? JSON.parse(storedSettings) : null
  const storedRespawnObject = storedSettings?.blueberry?.respawn
  if (storedRespawnObject) {
    const storedRespawnTimestamp = storedRespawnObject?.timestamp
    if (storedRespawnTimestamp && Date.now() - storedRespawnTimestamp < 10000) {
      settingsData.blueberry
        ? (settingsData.blueberry.respawn = storedRespawnObject)
        : (settingsData.blueberry = { respawn: storedRespawnObject })
    }
  }

  delete settingsData?.blueberry?.respawn?.timestamp

  // Proceso normal de actualización
  store.dispatch(setSettings(settingsData))
  window.localStorage.setItem('bb_settings', JSON.stringify(settingsData))
  console.log('ssss1', settingsData)

  if (hasToSave === true) {
    saveSettings(settingsData)
  }
}

export function getUserAvatar() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry ? settings.blueberry.avatar : undefined
}

export function getMusicUserConfig() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry ? settings.blueberry.music : undefined
}

export function updateMusicUserConfig(musicUserConfig) {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.blueberry.music = musicUserConfig
  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
  console.log('ssss2', settings)
  saveSettings(settings)
}

export function getEffectsUserConfig() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry ? settings.blueberry.effects : undefined
}

export function updateEffectsUserConfig(effectsUserConfig) {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.blueberry.effects = effectsUserConfig
  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
  console.log('ssss3', settings)
  saveSettings(settings)
}

export function getOnboardingConfig() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry
    ? settings.blueberry.onboarding
    : undefined
}

export function updateOnboardingConfig(onboardingConfig) {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.blueberry.onboarding = onboardingConfig
  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
  console.log('ssss4', settings)
  saveSettings(settings)
}

export function getLemonadeOnboardingConfig() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry && settings.blueberry.onboarding
    ? settings.blueberry.onboarding.lemonade
    : undefined
}

export function updateLemonadeOnboardingConfig(lemonadeOnboardingConfig) {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.blueberry.onboarding.lemonade = lemonadeOnboardingConfig
  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
  console.log('ssss4', settings)
  saveSettings(settings)
}

export function getRespawn() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry ? settings.blueberry.respawn : undefined
}

export function updateRespawn(respawnConfig) {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.blueberry.respawn = respawnConfig
  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
  console.log('ssss6', settings)
  return saveSettings(settings)
}

export function getPanelRenderCount() {
  const settings = store.getState().settings
  return settings.panelRenderCount
}

export function updatePanelRenderCount(panelRenderCount) {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.panelRenderCount = panelRenderCount
  store.dispatch(setSettings(settings))
  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
  console.log('ssss7', settings)
}

export function getDevMode() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.blueberry.devmode ? settings.blueberry.devmode : 0
}

export function getLoggedInViaToken() {
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  return settings && settings.loggedInViaToken ? settings.loggedInViaToken : 0
}

export const getSoundAssistanceApiConfig = async () => {
  const courseData = await getMainCourseData()
  const soundAssistance = courseData.sound_assitance
  store.dispatch(setSoundAssistance(!!soundAssistance))
}

export function getSoundAssistance() {
  const settings = store.getState().settings
  return settings.soundAssistance
}

export function setIsRankingShowed(isRankingShowed) {
  window.localStorage.setItem('bb_isRankingShowed', isRankingShowed)
}

export function getIsRankingShowed() {
  let isRankingShowed = window.localStorage.getItem('bb_isRankingShowed')
  // Cuidado porque aunque es número desde api, al sacarlo de localStorage es string
  isRankingShowed = parseInt(isRankingShowed || 1)

  // 0 NO mostrar   -   1 SI mostrar
  return isRankingShowed === 1
}

export function setIsDailyGoalCompleted(isCompleted) {
  window.localStorage.setItem('bb_isDailyGoalCompleted', isCompleted)
}

export function isDailyGoalCompleted() {
  const isDailyGoalCompleted = window.localStorage.getItem(
    'bb_isDailyGoalCompleted'
  )

  return isDailyGoalCompleted === 'true'
}

export const getTextToSpeech = () => {
  let textToSpeech = window.localStorage.getItem('bb_tts')

  if (textToSpeech && textToSpeech.custom_language) {
    textToSpeech = JSON.parse(textToSpeech)

    if (textToSpeech.requiresSoundAssistance && textToSpeech.programLanguage) {
      return textToSpeech
    }
  }

  return { requiresSoundAssistance: true, programLanguage: 'EN' }
}

export const setTextToSpeech = (requiresSoundAssistance, programLanguage) => {
  window.localStorage.setItem(
    'bb_tts',
    JSON.stringify({
      requiresSoundAssistance,
      programLanguage
    })
  )
}
